var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    height: `calc(${_vm.text * 2}px)`,
  })},[_c('ul',{staticClass:"flex flex-wrap items-center justify-center",class:{ 'mt-[10px]': !_vm.text },style:({
      color: _vm.data.fontColor,
      flexDirection: _vm.direction ? _vm.direction : 'row',
      width: _vm.width + 'px',
      fontSize: _vm.text ? _vm.text : '15px',
    })},[((_vm.data.sex === 'k' || _vm.data.sex === 'a') && _vm.data.age)?_c('li',[_c('span',{staticClass:"font-bold"},[_vm._v("Age: ")]),_vm._v(_vm._s(_vm.data.age)+" •  ")]):_vm._e(),(_vm.data.height)?_c('li',[_c('span',{staticClass:"font-bold"},[_vm._v("Height: ")]),_vm._v(_vm._s(_vm.data.height)+" •  ")]):_vm._e(),(_vm.data.sex !== 'k' && _vm.data.chest)?_c('li',[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.data.sex === "m" ? "Chest" : "Bust")+": ")]),_vm._v(_vm._s(_vm.data.chest)+" •  ")]):_vm._e(),(_vm.data.sex !== 'k' && _vm.data.waist)?_c('li',[_c('span',{staticClass:"font-bold"},[_vm._v("Waist: ")]),_vm._v(_vm._s(_vm.data.waist)+" •  ")]):_vm._e(),(_vm.data.sex !== 'k' && _vm.data.hip)?_c('li',[_c('span',{staticClass:"font-bold"},[_vm._v("Hip: ")]),_vm._v(_vm._s(_vm.data.hip)+" •  ")]):_vm._e(),(_vm.data.eyes)?_c('li',[_c('span',{staticClass:"font-bold"},[_vm._v("Eyes: ")]),_vm._v(_vm._s(_vm.data.eyes)+" •  ")]):_vm._e(),(_vm.data.hair)?_c('li',[_c('span',{staticClass:"font-bold"},[_vm._v("Hair: ")]),_vm._v(_vm._s(_vm.data.hair)+" •  ")]):_vm._e(),(_vm.data.shoe)?_c('li',[_c('span',{staticClass:"font-bold"},[_vm._v("Shoe: ")]),_vm._v(_vm._s(_vm.data.shoe)+"   ")]):_vm._e()]),(_vm.data.other)?_c('p',{staticClass:"text-center text-wrap",class:!_vm.text ? 'mt-[10px]' : 'mr-1 ',style:({
      color: _vm.data.fontColor,
      width: _vm.width,
      fontSize: _vm.text ? _vm.text : '15px',
    })},[_vm._v(" "+_vm._s(_vm.data.other)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }