import Vue from "vue";
import Vuex from "vuex";

// State Modules
import { comcard } from "./modules/comcard.js";
import { headshot } from "./modules/headshot.js";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    c: comcard,
    h: headshot,
  },
  actions: {
    async redirectPayment(_, checkout) {
      // Fetch Payment URL from API
      const response = await fetch(
        "https://designer-admin.modelplatform.com/api/create-payment-link",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(checkout),
        }
      );
      const data = await response.json();

      // Redirect User to Payment URL
      window.location.href = data.data;
      this.$router.push(data.data);
    },
  },
});
