<template>
  <div class="relative">
    <NoticeVue />
    <div
      id="comBack"
      :style="{ backgroundColor: getComBackFormInput.bgColor }"
      class="w-[422px] h-[647px] p-[12.5px]"
      :class="{ 'border border-indigo-600': border }"
    >
      <!-- Width Controller -->
      <div
        class="w-[398px] h-[623px] p-[10px]"
        :class="{ 'border border-red-600': border }"
      >
        <!-- Hidden File Type Input -->
        <input
          type="file"
          id="imageUpload"
          accept="image/png, image/jpeg"
          class="hidden"
          @change="onFileChange"
        />
        <!-- Images -->
        <div class="w-[378px] h-[558px] grid grid-cols-3 gap-[10px]">
          <img
            :src="
              getBackCroppedComImageOne
                ? getBackCroppedComImageOne
                : require('@/assets/img/portraitDefault.png')
            "
            alt="img1"
            class="w-[119.3px] h-[179.3px] object-cover cursor-pointer"
            @click="fakeClick"
          />
          <img
            :src="
              getBackCroppedComImageTwo
                ? getBackCroppedComImageTwo
                : require('@/assets/img/portraitDefault.png')
            "
            alt="img2"
            class="w-[119.3px] h-[179.3px] object-cover cursor-pointer"
            @click="fakeClick"
          />
          <img
            :src="
              getBackCroppedComImageThree
                ? getBackCroppedComImageThree
                : require('@/assets/img/portraitDefault.png')
            "
            alt="img3"
            class="w-[119.3px] h-[179.3px] object-cover cursor-pointer"
            @click="fakeClick"
          />
          <img
            :src="
              getBackCroppedComImageFour
                ? getBackCroppedComImageFour
                : require('@/assets/img/portraitDefault.png')
            "
            alt="img4"
            class="w-[119.3px] h-[179.3px] object-cover cursor-pointer"
            @click="fakeClick"
          />
          <img
            :src="
              getBackCroppedComImageFive
                ? getBackCroppedComImageFive
                : require('@/assets/img/portraitDefault.png')
            "
            alt="img5"
            class="w-[119.3px] h-[179.3px] object-cover cursor-pointer"
            @click="fakeClick"
          />
          <img
            :src="
              getBackCroppedComImageSix
                ? getBackCroppedComImageSix
                : require('@/assets/img/portraitDefault.png')
            "
            alt="img6"
            class="w-[119.3px] h-[179.3px] object-cover cursor-pointer"
            @click="fakeClick"
          />
          <img
            :src="
              getBackCroppedComImageSeven
                ? getBackCroppedComImageSeven
                : require('@/assets/img/portraitDefault.png')
            "
            alt="img7"
            class="w-[119.3px] h-[179.3px] object-cover cursor-pointer"
            @click="fakeClick"
          />
          <img
            :src="
              getBackCroppedComImageEight
                ? getBackCroppedComImageEight
                : require('@/assets/img/portraitDefault.png')
            "
            alt="img8"
            class="w-[119.3px] h-[179.3px] object-cover cursor-pointer"
            @click="fakeClick"
          />
          <img
            :src="
              getBackCroppedComImageNine
                ? getBackCroppedComImageNine
                : require('@/assets/img/portraitDefault.png')
            "
            alt="img9"
            class="w-[119.3px] h-[179.3px] object-cover cursor-pointer"
            @click="fakeClick"
          />
        </div>
        <!-- Text -->
        <UserInfo :text="'10px'" :width="388" :data="getComBackFormInput" />
      </div>
    </div>
    <WaterMark />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import NoticeVue from "@/utils/Notice.vue";
import WaterMark from "@/utils/WaterMark.vue";
import UserInfo from "@/utils/UserInfo.vue";

export default {
  name: "ComBackTemplateNineteen",
  data() {
    return {
      border: true,
      image: "",
    };
  },
  components: {
    NoticeVue,
    WaterMark,
    UserInfo,
  },
  computed: {
    ...mapGetters([
      "getComBorder",
      "getComBackFormInput",
      "getBackCroppedComImageOne",
      "getBackCroppedComImageTwo",
      "getBackCroppedComImageThree",
      "getBackCroppedComImageFour",
      "getBackCroppedComImageFive",
      "getBackCroppedComImageSix",
      "getBackCroppedComImageSeven",
      "getBackCroppedComImageEight",
      "getBackCroppedComImageNine",
    ]),
  },
  methods: {
    ...mapMutations(["setBackComImage"]),
    fakeClick(e) {
      this.image = e.target.alt;
      document.getElementById("imageUpload").click();
    },
    onFileChange(e) {
      if (this.image === "img1") {
        this.setBackComImage({
          id: 1,
          backComImage: URL.createObjectURL(e.target.files[0]),
        });
      } else if (this.image === "img2") {
        this.setBackComImage({
          id: 2,
          backComImage: URL.createObjectURL(e.target.files[0]),
        });
      } else if (this.image === "img3") {
        this.setBackComImage({
          id: 3,
          backComImage: URL.createObjectURL(e.target.files[0]),
        });
      } else if (this.image === "img4") {
        this.setBackComImage({
          id: 4,
          backComImage: URL.createObjectURL(e.target.files[0]),
        });
      } else if (this.image === "img5") {
        this.setBackComImage({
          id: 5,
          backComImage: URL.createObjectURL(e.target.files[0]),
        });
      } else if (this.image === "img6") {
        this.setBackComImage({
          id: 6,
          backComImage: URL.createObjectURL(e.target.files[0]),
        });
      } else if (this.image === "img7") {
        this.setBackComImage({
          id: 7,
          backComImage: URL.createObjectURL(e.target.files[0]),
        });
      } else if (this.image === "img8") {
        this.setBackComImage({
          id: 8,
          backComImage: URL.createObjectURL(e.target.files[0]),
        });
      } else {
        this.setBackComImage({
          id: 9,
          backComImage: URL.createObjectURL(e.target.files[0]),
        });
      }
      e.target.value = null;
    },
  },
  watch: {
    getComBorder(newInput) {
      this.border = newInput;
    },
  },
  created() {
    this.border = this.getComBorder;
  },
};
</script>
