<template>
  <div
    class="flex flex-col h-screen overflow-x-hidden bg-primary text-white drop-shadow-xl items-center justify-center"
  >
    <div class="text-center mt-16 mb-3">
      <h1 class="font-bold text-lg w-28">Change Template</h1>
      <p class="text-xs w-28">Choose a template to start editing.</p>
    </div>
    <ul class="flex flex-col space-y-5 overflow-y-auto px-3 py-5">
      <li v-for="(img, i) in images" :key="i" @click="activateComTemplate(i)">
        <img
          class="w-28 cursor-pointer"
          :src="img"
          :alt="`template-${i}`"
          :class="{ 'border-4 border-black': activeComTemplate === i }"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ComBackBar",
  data() {
    return {
      images: [],
      activeComTemplate: 0,
    };
  },
  computed: {
    ...mapGetters(["getComOrientation"]),
  },
  methods: {
    ...mapMutations(["setActiveBackPTemplate", "setActiveBackLTemplate"]),
    importAll(r) {
      r.keys().forEach((key) => this.images.push(r(key)));
    },
    activateComTemplate(template) {
      this.activeComTemplate = template;
      if (this.getComOrientation === "p") {
        this.setActiveBackPTemplate({
          activeBackPTemplate: this.activeComTemplate,
        });
      } else {
        this.setActiveBackLTemplate({
          activeBackLTemplate: this.activeComTemplate,
        });
      }
    },
  },
  mounted() {
    if (this.getComOrientation === "l") {
      this.images = [];
      this.importAll(
        require.context(
          "../../assets/img/templates/acom/back/landscape",
          true,
          /\.jpg$/
        )
      );
    } else {
      this.images = [];
      this.importAll(
        require.context(
          "../../assets/img/templates/acom/back/portrait",
          true,
          /\.jpg$/
        )
      );
    }
  },
  watch: {
    getComOrientation(newOrientation) {
      if (newOrientation === "l") {
        this.images = [];
        this.importAll(
          require.context(
            "../../assets/img/templates/acom/back/landscape",
            true,
            /\.jpg$/
          )
        );
      } else {
        this.images = [];
        this.importAll(
          require.context(
            "../../assets/img/templates/acom/back/portrait",
            true,
            /\.jpg$/
          )
        );
      }
    },
  },
};
</script>
