var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('NoticeVue'),_c('div',{staticClass:"relative p-[12.5px] z-10",class:{ 'border border-indigo-600': _vm.border },style:([
      _vm.portrait.main,
      {
        backgroundColor: _vm.getComFrontFormInput.bgColor,
      },
    ]),attrs:{"id":"main"}},[(_vm.getFrontCroppedComImage)?_c('img',{staticClass:"z-[-1]",style:(_vm.portrait.img),attrs:{"src":_vm.getFrontCroppedComImage,"alt":"front-image","id":"image"}}):_c('img',{staticClass:"z-[-1]",style:(_vm.portrait.img),attrs:{"alt":"default","id":"preview-image","src":require("@/assets/img/default.png")}}),_c('div',{staticClass:"w-[100%] h-full flex flex-row z-30",class:{ 'border border-red-600': _vm.border }},[_c('div',{staticClass:"flex flex-col rotate-[-90deg]",style:([
          _vm.getComOrientation === 'p' ? _vm.portrait.text : _vm.landscape.text,
          {
            fontFamily: _vm.getComFrontFormInput.font,
            textAlign: _vm.getComFrontFormInput.textAlign,
            height: `calc(${_vm.nameHeight()}+${_vm.tagHeight()})`,
          },
        ]),attrs:{"id":"text"}},[_c('span',{style:([
            _vm.getComOrientation === 'p' ? _vm.portrait.h3 : _vm.landscape.h3,
            {
              color: _vm.getComFrontFormInput.nameColor,
              fontSize: _vm.getComFrontFormInput.nameSize + 'px',
              lineHeight: _vm.nameHeight(),
            },
          ]),attrs:{"id":"name"}},[_vm._v(" "+_vm._s(_vm.getComFrontFormInput.name === "" ? "Your Name Here" : _vm.getComFrontFormInput.name)+" ")]),_c('span',{style:([
            _vm.getComOrientation === 'p' ? _vm.portrait.p : _vm.landscape.p,
            {
              color: _vm.getComFrontFormInput.tagColor,
              fontSize: _vm.getComFrontFormInput.tagSize + 'px',
              lineHeight: _vm.getComFrontFormInput.tagSize + 'px',
            },
          ]),attrs:{"id":"tag"}},[_vm._v(" "+_vm._s(_vm.getComFrontFormInput.tagline)+" ")])])])]),_c('WaterMark')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }