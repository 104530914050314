var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"flex flex-col items-center justify-center h-screen space-y-5 text-xs text-gray-600 font-semibold px-5"},[_c('li',{staticClass:"flex flex-col items-center justify-center",class:{
      'text-primary': _vm.activeForm === 'front',
    }},[_c('VueIcons',{staticClass:"w-4 h-4",attrs:{"name":"pencil"}}),_c('span',[_vm._v("Front")])],1),_c('li',{staticClass:"flex flex-col items-center justify-center",class:{
      'text-primary': _vm.activeForm === 'back',
    }},[_c('VueIcons',{staticClass:"w-4 h-4",attrs:{"name":"pencil"}}),_c('span',[_vm._v("Back")])],1),_c('li',{staticClass:"flex flex-col items-center justify-center",class:{
      'text-primary': _vm.activeForm === 'checkout',
    }},[_c('VueIcons',{staticClass:"w-6 h-6",attrs:{"name":"cart"}}),_c('span',[_vm._v("Checkout")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }