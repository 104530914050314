<template>
  <div class="flex flex-row items-center justify-center bg-gray-200">
    <!-- Left Form Switch -->
    <LeftBar />
    <!-- Forms -->
    <div class="flex mr-20">
      <router-view></router-view>
    </div>
    <!-- Editor -->
    <CropModal />
    <template v-if="this.$router.currentRoute.fullPath.includes('/comp-cards')">
      <div
        class="flex flex-1"
        :class="
          getComOrientation === 'p'
            ? 'lg:mr-32 2xl:mr-64'
            : 'md:mr-[20px] lg:mr-[20px] 2xl:mr-64'
        "
        v-if="this.$router.currentRoute.fullPath.includes('/f')"
      >
        <template v-if="getComOrientation === 'p'">
          <BackTemplateOne v-if="getActiveFrontPTemplate === 0" />
          <BackTemplateTwo v-if="getActiveFrontPTemplate === 1" />
          <BackTemplateThree v-if="getActiveFrontPTemplate === 2" />
          <BackTemplateFour v-if="getActiveFrontPTemplate === 3" />
          <BackTemplateFive v-if="getActiveFrontPTemplate === 4" />
          <BackTemplateSix v-if="getActiveFrontPTemplate === 5" />
          <BackTemplateSeven v-if="getActiveFrontPTemplate === 6" />
          <BackTemplateEight v-if="getActiveFrontPTemplate === 7" />
          <BackTemplateNine v-if="getActiveFrontPTemplate === 8" />
        </template>
        <template v-else>
          <BackTemplateOneL v-if="getActiveFrontLTemplate === 0" />
          <BackTemplateTwoL v-if="getActiveFrontLTemplate === 1" />
          <BackTemplateThreeL v-if="getActiveFrontLTemplate === 2" />
        </template>
      </div>
      <div
        class="flex flex-1"
        :class="
          getComOrientation === 'p'
            ? 'lg:mr-32 2xl:mr-64'
            : 'md:mr-[20px] lg:mr-[20px] 2xl:mr-64'
        "
        v-else-if="this.$router.currentRoute.fullPath.includes('/b')"
      >
        <!-- ComCard Back Templates -->
        <template v-if="getComOrientation === 'l'">
          <ComBackTemplateOneL v-if="getActiveBackLTemplate === 0" />
          <ComBackTemplateTwoL v-if="getActiveBackLTemplate === 1" />
          <ComBackTemplateThreeL v-if="getActiveBackLTemplate === 2" />
        </template>
        <template v-else>
          <ComBackTemplateOne v-if="getActiveBackPTemplate === 0" />
          <ComBackTemplateTwo v-if="getActiveBackPTemplate === 1" />
          <ComBackTemplateThree v-if="getActiveBackPTemplate === 2" />
          <ComBackTemplateFour v-if="getActiveBackPTemplate === 3" />
          <ComBackTemplateFive v-if="getActiveBackPTemplate === 4" />
          <ComBackTemplateSix v-if="getActiveBackPTemplate === 5" />
          <ComBackTemplateSeven v-if="getActiveBackPTemplate === 6" />
          <ComBackTemplateEight v-if="getActiveBackPTemplate === 7" />
          <ComBackTemplateNine v-if="getActiveBackPTemplate === 8" />
          <ComBackTemplateTen v-if="getActiveBackPTemplate === 9" />
          <ComBackTemplateEleven v-if="getActiveBackPTemplate === 10" />
          <ComBackTemplateTwelve v-if="getActiveBackPTemplate === 11" />
          <ComBackTemplateThirteen v-if="getActiveBackPTemplate === 12" />
          <ComBackTemplateFourteen v-if="getActiveBackPTemplate === 13" />
          <ComBackTemplateFifteen v-if="getActiveBackPTemplate === 14" />
          <ComBackTemplateSixteen v-if="getActiveBackPTemplate === 15" />
          <ComBackTemplateSeventeen v-if="getActiveBackPTemplate === 16" />
          <ComBackTemplateEighteen v-if="getActiveBackPTemplate === 17" />
          <ComBackTemplateNineteen v-if="getActiveBackPTemplate === 18" />
          <ComBackTemplateTwenty v-if="getActiveBackPTemplate === 19" />
        </template>
      </div>
      <div class="flex flex-1" v-else>
        <CheckoutTemplate />
      </div>
    </template>
    <template v-else>
      <div
        class="flex flex-1"
        :class="
          getHeadOrientation === 'p'
            ? 'lg:mr-32 2xl:mr-64'
            : 'md:mr-[20px] lg:mr-[20px] 2xl:mr-64'
        "
        v-if="this.$router.currentRoute.fullPath.includes('/f')"
      >
        <TemplateOne v-if="getActiveHeadTemplate === 0" />
        <TemplateTwo v-if="getActiveHeadTemplate === 1" />
        <TemplateThree v-if="getActiveHeadTemplate === 2" />
        <TemplateFour v-if="getActiveHeadTemplate === 3" />
        <TemplateFive v-if="getActiveHeadTemplate === 4" />
      </div>
      <div
        class="flex flex-1"
        :class="
          getHeadOrientation === 'p'
            ? 'lg:mr-32 2xl:mr-64'
            : 'md:mr-[20px] lg:mr-[20px] 2xl:mr-64'
        "
        v-else-if="this.$router.currentRoute.fullPath.includes('/b')"
      >
        <BackHeadTemplate />
      </div>
      <div class="flex flex-1" v-else>
        <CheckoutTemplate />
      </div>
    </template>
    <!-- Template Bar -->
    <template v-if="this.$router.currentRoute.fullPath.includes('/comp-cards')">
      <ComFrontBar v-if="this.$router.currentRoute.fullPath.includes('/fc')" />
      <ComBackBar v-if="this.$router.currentRoute.fullPath.includes('/bc')" />
    </template>
    <template v-else>
      <HeadBar v-if="this.$router.currentRoute.fullPath.includes('/f')" />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import LeftBar from "@/components/common/LeftBar.vue";
import CropModal from "@/components/common/CropModal.vue";
import HeadBar from "@/components/TemplateBar/HeadBar.vue";
import ComBackBar from "@/components/TemplateBar/ComBackBar.vue";
import ComFrontBar from "@/components/TemplateBar/ComFrontBar.vue";

// Headshot Front Templates
import TemplateOne from "@/components/common/FrontTemplates/TemplateOne.vue";
import TemplateTwo from "@/components/common/FrontTemplates/TemplateTwo.vue";
import TemplateFour from "@/components/common/FrontTemplates/TemplateFour.vue";
import TemplateFive from "@/components/common/FrontTemplates/TemplateFive.vue";
import TemplateThree from "@/components/common/FrontTemplates/TemplateThree.vue";

// Headshot Back Template
import BackHeadTemplate from "@/components/BackHeadTemplate.vue";

// ACOM Front Portrait Templates
import BackTemplateOne from "@/components/common/ComTemplates/Front/Portrait/BackTemplateOne.vue";
import BackTemplateTwo from "@/components/common/ComTemplates/Front/Portrait/BackTemplateTwo.vue";
import BackTemplateSix from "@/components/common/ComTemplates/Front/Portrait/BackTemplateSix.vue";
import BackTemplateFour from "@/components/common/ComTemplates/Front/Portrait/BackTemplateFour.vue";
import BackTemplateFive from "@/components/common/ComTemplates/Front/Portrait/BackTemplateFive.vue";
import BackTemplateNine from "@/components/common/ComTemplates/Front/Portrait/BackTemplateNine.vue";
import BackTemplateThree from "@/components/common/ComTemplates/Front/Portrait/BackTemplateThree.vue";
import BackTemplateSeven from "@/components/common/ComTemplates/Front/Portrait/BackTemplateSeven.vue";
import BackTemplateEight from "@/components/common/ComTemplates/Front/Portrait/BackTemplateEight.vue";

// ACOM Landscape Portrait Templates
import BackTemplateOneL from "@/components/common/ComTemplates/Front/Landscape/BackTemplateOneL.vue";
import BackTemplateTwoL from "@/components/common/ComTemplates/Front/Landscape/BackTemplateTwoL.vue";
import BackTemplateThreeL from "@/components/common/ComTemplates/Front/Landscape/BackTemplateThreeL.vue";

// ACOM Back Portrait Templates
import ComBackTemplateOne from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateOne.vue";
import ComBackTemplateTwo from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateTwo.vue";
import ComBackTemplateSix from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateSix.vue";
import ComBackTemplateTen from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateTen.vue";
import ComBackTemplateFour from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateFour.vue";
import ComBackTemplateFive from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateFive.vue";
import ComBackTemplateNine from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateNine.vue";
import ComBackTemplateThree from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateThree.vue";
import ComBackTemplateSeven from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateSeven.vue";
import ComBackTemplateEight from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateEight.vue";
import ComBackTemplateEleven from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateEleven.vue";
import ComBackTemplateTwelve from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateTwelve.vue";

// ACOM Back Landscape Templates
import ComBackTemplateOneL from "@/components/common/ComTemplates/Back/Landscape/ComBackTemplateOneL.vue";
import ComBackTemplateTwoL from "@/components/common/ComTemplates/Back/Landscape/ComBackTemplateTwoL.vue";
import ComBackTemplateThreeL from "@/components/common/ComTemplates/Back/Landscape/ComBackTemplateThreeL.vue";

// Checkout Template
import CheckoutTemplate from "@/components/common/CheckoutTemplate.vue";
import ComBackTemplateFifteen from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateFifteen.vue";
import ComBackTemplateThirteen from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateThirteen.vue";
import ComBackTemplateFourteen from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateFourteen.vue";
import ComBackTemplateSixteen from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateSixteen.vue";
import ComBackTemplateSeventeen from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateSeventeen.vue";
import ComBackTemplateEighteen from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateEighteen.vue";
import ComBackTemplateNineteen from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateNineteen.vue";
import ComBackTemplateTwenty from "@/components/common/ComTemplates/Back/Portrait/ComBackTemplateTwenty.vue";

export default {
  name: "ComCard",
  components: {
    LeftBar,
    HeadBar,
    CropModal,
    ComBackBar,
    ComFrontBar,
    TemplateOne,
    TemplateTwo,
    TemplateFour,
    TemplateFive,
    TemplateThree,
    BackTemplateOne,
    BackTemplateTwo,
    BackTemplateSix,
    BackHeadTemplate,
    BackTemplateOneL,
    BackTemplateTwoL,
    CheckoutTemplate,
    BackTemplateFour,
    BackTemplateFive,
    BackTemplateNine,
    BackTemplateThree,
    BackTemplateSeven,
    BackTemplateEight,
    BackTemplateThreeL,
    ComBackTemplateOne,
    ComBackTemplateTwo,
    ComBackTemplateSix,
    ComBackTemplateFour,
    ComBackTemplateFive,
    ComBackTemplateOneL,
    ComBackTemplateTwoL,
    ComBackTemplateThree,
    ComBackTemplateSeven,
    ComBackTemplateEight,
    ComBackTemplateThreeL,
    ComBackTemplateNine,
    ComBackTemplateTen,
    ComBackTemplateEleven,
    ComBackTemplateTwelve,
    ComBackTemplateThirteen,
    ComBackTemplateFourteen,
    ComBackTemplateFifteen,
    ComBackTemplateSixteen,
    ComBackTemplateSeventeen,
    ComBackTemplateEighteen,
    ComBackTemplateNineteen,
    ComBackTemplateTwenty,
  },
  methods: {
    ...mapMutations(["setHeadOrientation", "setComOrientation"]),
  },
  computed: {
    ...mapGetters([
      "getComOrientation",
      "getHeadOrientation",
      "getActiveHeadTemplate",
      "getActiveBackPTemplate",
      "getActiveBackLTemplate",
      "getActiveFrontLTemplate",
      "getActiveFrontPTemplate",
    ]),
  },
  created() {
    localStorage.clear();
    this.setComOrientation({ comOrientation: "p" });
    this.setHeadOrientation({ headOrientation: "p" });
  },
};
</script>
