<template>
  <div>
    <div id="back" class="relative" v-if="getBackCroppedHeadImage">
      <img
        :src="getBackCroppedHeadImage"
        alt="back-template"
        :class="
          getHeadOrientation === 'p'
            ? 'w-[519px] h-[639px]'
            : 'w-[639px] h-[519px]'
        "
      />
    </div>
    <div
      v-else
      id="backHeadPlaceholder"
      class="w-[200px] h-[200px] rounded-md bg-white flex items-center justify-center"
    >
      <p class="text-sm font-bold text-black text-center">
        Please Upload a File to Get Started
      </p>
    </div>
    <!-- Watermark -->
    <WaterMark v-if="getBackCroppedHeadImage" class="ml-24" />
    <!-- PDF TO IMAGE -->
    <canvas
      id="the-canvas"
      :width="getHeadOrientation === 'p' ? 519 : 639"
      :height="getHeadOrientation === 'p' ? 639 : 519"
      style="display: none"
    ></canvas>
    <!-- PDF TO IMAGE -->
  </div>
</template>

<script>
import WaterMark from "@/utils/WaterMark.vue";
import { mapGetters } from "vuex";

export default {
  name: "BackHeadTemplate",
  computed: {
    ...mapGetters(["getHeadOrientation", "getBackCroppedHeadImage"]),
  },
  components: { WaterMark },
};
</script>
