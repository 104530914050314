<template>
  <div class="relative">
    <NoticeVue />
    <div
      id="comBack"
      class="w-[422px] h-[647px] p-[12.5px]"
      :class="{ 'border border-indigo-600': border }"
      :style="{ backgroundColor: getComBackFormInput.bgColor }"
    >
      <!-- Width Controller -->
      <div
        class="w-[398px] h-[623px] p-[10px]"
        :class="{ 'border border-red-600': border }"
      >
        <!-- Hidden File Type Input -->
        <input
          type="file"
          id="imageUpload"
          accept="image/png, image/jpeg"
          class="hidden"
          @change="onFileChange"
        />
        <!-- Images -->
        <div
          class="w-[378px] h-[525.5px] grid grid-cols-2 gap-[5px] place-items-start justify-content-start"
        >
          <img
            :src="
              getBackCroppedComImageOne
                ? getBackCroppedComImageOne
                : require('@/assets/img/landscapeDefault.png')
            "
            alt="img1"
            class="w-[378px] h-[525.5px] object-cover cursor-pointer col-span-2"
            @click="fakeClick"
          />
        </div>
        <!-- Text -->
        <div class="h-[77.5px] pt-[5px]">
          <UserInfo :width="378" :text="'15px'" :data="getComBackFormInput" />
        </div>
      </div>
    </div>
    <WaterMark />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import NoticeVue from "@/utils/Notice.vue";
import WaterMark from "@/utils/WaterMark.vue";
import UserInfo from "@/utils/UserInfo.vue";

export default {
  name: "ComBackTemplateTwenty",
  data() {
    return {
      border: true,
      image: "",
    };
  },
  components: {
    NoticeVue,
    WaterMark,
    UserInfo,
  },
  computed: {
    ...mapGetters([
      "getComBorder",
      "getComBackFormInput",
      "getBackCroppedComImageOne",
    ]),
  },
  methods: {
    ...mapMutations(["setBackComImage"]),
    fakeClick(e) {
      this.image = e.target.alt;
      document.getElementById("imageUpload").click();
    },
    onFileChange(e) {
      if (this.image === "img1") {
        this.setBackComImage({
          id: 1,
          backComImage: URL.createObjectURL(e.target.files[0]),
        });
      }
      e.target.value = null;
    },
  },
  watch: {
    getComBorder(newInput) {
      this.border = newInput;
    },
  },
  created() {
    this.border = this.getComBorder;
  },
};
</script>
