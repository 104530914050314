var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('NoticeVue'),_c('div',{staticClass:"w-[647px] h-[422px] p-[12.5px]",class:{ 'border border-indigo-600': _vm.border },style:({ backgroundColor: _vm.getComBackFormInput.bgColor }),attrs:{"id":"comBack"}},[_c('div',{staticClass:"w-[623px] h-[398px] p-[10px]",class:{ 'border border-red-600': _vm.border }},[_c('div',{staticClass:"grid grid-cols-3 place-items-center justify-content-center gap-x-[5px]"},[_c('input',{staticClass:"hidden",attrs:{"type":"file","id":"imageUpload","accept":"image/png, image/jpeg"},on:{"change":_vm.onFileChange}}),_c('img',{staticClass:"w-[197.6px] h-[302.6px] object-cover cursor-pointer",attrs:{"src":_vm.getBackCroppedComImageOne
              ? _vm.getBackCroppedComImageOne
              : require('@/assets/img/portraitDefault.png'),"alt":"img1"},on:{"click":_vm.fakeClick}}),_c('img',{staticClass:"w-[197.6px] h-[302.6px] object-cover cursor-pointer",attrs:{"src":_vm.getBackCroppedComImageTwo
              ? _vm.getBackCroppedComImageTwo
              : require('@/assets/img/portraitDefault.png'),"alt":"img2"},on:{"click":_vm.fakeClick}}),_c('img',{staticClass:"w-[197.6px] h-[302.6px] object-cover cursor-pointer",attrs:{"src":_vm.getBackCroppedComImageThree
              ? _vm.getBackCroppedComImageThree
              : require('@/assets/img/portraitDefault.png'),"alt":"img3"},on:{"click":_vm.fakeClick}})]),_c('UserInfo',{attrs:{"width":603,"data":_vm.getComBackFormInput}})],1)]),_c('WaterMark')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }