<template>
  <div>
    <NoticeVue />
    <div
      class="flex-1 flex flex-row items-center justify-center space-x-5 mr-24 relative"
    >
      <template
        v-if="this.$router.currentRoute.fullPath.includes('/comp-cards')"
      >
        <!-- Front -->
        <div class="relative w-[100%]">
          <img
            oncontextmenu="return false;"
            :src="getComFrontPdf"
            alt="front-result"
            class="sm:w-[250px] sm:h-auto xl:w-[350px] xl:h-auto 2xl:w-[450px] 2xl:h-auto"
          />
          <!-- Watermark -->
          <WaterMark />
        </div>
        <!-- Back -->
        <div v-if="getComBackPdf" class="relative w-[100%]">
          <img
            oncontextmenu="return false;"
            :src="getComBackPdf"
            alt="front-result"
            class="sm:w-[250px] sm:h-auto xl:w-[350px] xl:h-auto 2xl:w-[450px] 2xl:h-auto"
          />
          <!-- Watermark -->
          <WaterMark />
        </div>
      </template>
      <template v-else>
        <!-- Front -->
        <div
          class="relative"
          :class="getHeadOrientation === 'p' ? 'w-[99.1%]' : 'w-[100%]'"
        >
          <img
            oncontextmenu="return false;"
            :src="getFrontPdf"
            alt="front-result"
            class="sm:w-[250px] sm:h-auto xl:w-[350px] xl:h-auto 2xl:w-[450px] 2xl:h-auto"
          />
          <!-- Watermark -->
          <WaterMark />
        </div>
        <!-- Back -->
        <div
          v-if="getBackPdf"
          class="relative"
          :class="getHeadOrientation === 'p' ? 'w-[100%]' : 'w-[99.1%]'"
        >
          <img
            oncontextmenu="return false;"
            :src="getBackPdf"
            alt="front-result"
            class="sm:w-[250px] sm:h-auto xl:w-[350px] xl:h-auto 2xl:w-[450px] 2xl:h-auto"
          />
          <!-- Watermark -->
          <WaterMark />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// import { jsPDF } from "jspdf";
import { mapGetters } from "vuex";

import NoticeVue from "@/utils/Notice.vue";
import WaterMark from "@/utils/WaterMark.vue";

export default {
  name: "CheckoutTemplate",
  components: {
    NoticeVue,
    WaterMark,
  },
  computed: {
    ...mapGetters([
      "getBackPdf",
      "getFrontPdf",
      "getComBackPdf",
      "getComFrontPdf",
      "getComOrientation",
      "getHeadOrientation",
    ]),
  },
  // methods: {
  //   ...mapMutations(["setFinalHeadPdf", "setFinalComPdf"]),
  // },
  // created() {
  //   let doc = "";
  //   if (this.$router.currentRoute.fullPath.includes("/comp-cards")) {
  //     if (this.getComOrientation === "p") {
  //       doc = new jsPDF({
  //         orientation: "portrait",
  //         unit: "in",
  //         userUnit: 300,
  //         format: [8.625, 5.625],
  //       });
  //       doc.addImage(this.getComFrontPdf, "PNG", 0.125, 0.125, 5.375, 8.375);
  //       doc.addPage();
  //       doc.addImage(this.getComBackPdf, "PNG", 0.125, 0.125, 5.375, 8.375);
  //     } else {
  //       doc = new jsPDF({
  //         orientation: "landscape",
  //         unit: "in",
  //         userUnit: 300,
  //         format: [5.625, 8.625],
  //       });
  //       doc.addImage(this.getComFrontPdf, "PNG", 0.125, 0.125, 8.375, 5.375);
  //       doc.addPage();
  //       doc.addImage(this.getComBackPdf, "PNG", 0.125, 0.125, 8.375, 5.375);
  //     }
  //     const img64 = btoa(doc.output());
  //     this.setFinalComPdf({ finalComPdf: `${img64}` });
  //   } else {
  //     if (this.getHeadOrientation === "p") {
  //       doc = new jsPDF({
  //         orientation: "portrait",
  //         unit: "in",
  //         userUnit: 300,
  //         format: [10.75, 8.75],
  //       });
  //       doc.addImage(this.getFrontPdf, "PNG", 0.25, 0.25, 8.25, 10.25);
  //       doc.addPage();
  //       doc.addImage(this.getBackPdf, "PNG", 0.25, 0.25, 8.25, 10.25);
  //     } else {
  //       doc = new jsPDF({
  //         orientation: "landscape",
  //         unit: "in",
  //         userUnit: 300,
  //         format: [8.75, 10.75],
  //       });
  //       doc.addImage(this.getFrontPdf, "PNG", 0.25, 0.25, 10.25, 8.25);
  //       doc.addPage();
  //       doc.addImage(this.getBackPdf, "PNG", 0.25, 0.25, 10.25, 8.25);
  //     }
  //     const img64 = btoa(doc.output());
  //     this.setFinalHeadPdf({ finalHeadPdf: `${img64}` });
  //   }
  // },
};
</script>
