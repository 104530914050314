<template>
  <form
    id="back-com-form"
    @submit="onSubmit"
    class="relative flex flex-col items-start justify-start bg-white lg:w-[300px] xl:w-[400px] 2xl:w-[500px] lg:h-[400px] xl:h-[500px] 2xl:h-[725px] overflow-hidden rounded-md drop-shadow-xl p-5 text-xs"
    :class="{ 'overflow-y-auto': !loading }"
  >
    <div class="w-[100%]">
      <!-- Info -->
      <div class="mb-3 w-[100%]">
        <h1 class="text-lg font-semibold text-black">Info</h1>
        <p class="text-xs font-semibold text-gray-500">
          Add your information below.
        </p>
      </div>
      <!-- User Data -->
      <h1 class="my-3 w-[100%] text-lg font-semibold text-black">Your Data</h1>
      <!-- BG Color -->
      <div
        class="my-1 w-[100%] flex flex-row items-center justify-between text-gray-500 text-xs border-b border-gray-500"
      >
        <span class="flex w-[100%] text-xs text-gray-400">BG Color</span>
        <div class="flex flex-row items-center justify-end space-x-3 w-[100%]">
          <span class="uppercase">{{ backFormInput.bgColor }}</span>
          <Verte
            model="hex"
            menuPosition="right"
            :enableAlpha="false"
            :draggable="false"
            :showHistory="null"
            class="border border-gray-300 rounded-full mb-[5.5px] cursor-pointer"
            v-model="backFormInput.bgColor"
          />
        </div>
      </div>
      <!-- Font Color -->
      <div
        class="my-1 w-[100%] flex flex-row items-center justify-between text-gray-500 text-xs border-b border-gray-500"
      >
        <span class="flex w-[100%] text-xs text-gray-400">Font Color</span>
        <div class="flex flex-row items-center justify-end space-x-3 w-[100%]">
          <span class="uppercase">{{ backFormInput.fontColor }}</span>
          <Verte
            model="hex"
            menuPosition="right"
            :enableAlpha="false"
            :draggable="false"
            :showHistory="null"
            class="border border-gray-300 rounded-full mb-[5.5px] cursor-pointer"
            v-model="backFormInput.fontColor"
          />
        </div>
      </div>
      <!-- Sex & Height -->
      <div
        class="w-[100%] flex flex-row space-x-3 mb-3 border-b border-gray-300"
      >
        <select name="" id="" class="w-[100%]" v-model="backFormInput.sex">
          <option value="m">Male</option>
          <option value="f">Female</option>
          <option value="k">Kids</option>
          <option value="a">All</option>
        </select>
        <input
          type="text"
          placeholder="Height"
          class="flex-1 px-3 py-2 focus:outline-primary"
          v-model="backFormInput.height"
        />
      </div>
      <!-- Chest & Waist -->
      <div
        v-if="backFormInput.sex !== 'k'"
        class="w-[100%] flex flex-row space-x-3 mb-3 border-b border-gray-300"
      >
        <input
          type="text"
          :placeholder="backFormInput.sex === 'f' ? 'Bust' : 'Chest'"
          class="flex-1 px-3 py-2 focus:outline-primary"
          v-model="backFormInput.chest"
        />
        <input
          type="text"
          placeholder="Waist"
          class="flex-1 px-3 py-2 focus:outline-primary"
          v-model="backFormInput.waist"
        />
      </div>
      <!-- Hip & Eyes -->
      <div
        class="w-[100%] flex flex-row space-x-3 mb-3 border-b border-gray-300"
      >
        <input
          v-if="backFormInput.sex !== 'k'"
          type="text"
          placeholder="Hip"
          class="flex-1 px-3 py-2 focus:outline-primary"
          v-model="backFormInput.hip"
        />
        <input
          type="text"
          placeholder="Eyes"
          class="flex-1 px-3 py-2 focus:outline-primary"
          v-model="backFormInput.eyes"
        />
      </div>
      <!-- Hair & Shoe -->
      <div
        class="w-[100%] flex flex-row space-x-3 mb-3 border-b border-gray-300"
      >
        <input
          type="text"
          placeholder="Hair"
          class="flex-1 px-3 py-2 focus:outline-primary"
          v-model="backFormInput.hair"
        />
        <input
          type="text"
          placeholder="Shoe"
          class="flex-1 px-3 py-2 focus:outline-primary"
          v-model="backFormInput.shoe"
        />
      </div>
      <!-- Age -->
      <div
        v-if="backFormInput.sex === 'k' || backFormInput.sex === 'a'"
        class="w-[100%] flex flex-row space-x-3 mb-3 border-b border-gray-300"
      >
        <input
          type="number"
          placeholder="Age"
          class="flex-1 px-3 py-2 focus:outline-primary"
          v-model="backFormInput.age"
        />
      </div>
      <!-- Other Info -->
      <textarea
        rows="10"
        class="w-[100%] focus:outline-primary border border-gray-300 rounded-md px-3 py-2 text-justify"
        v-model="backFormInput.other"
      />
    </div>
    <!-- Previous & Next -->
    <div class="mt-3 w-[100%] flex flex-row items-center justify-between">
      <button
        type="button"
        @click="toFrontForm"
        class="flex flex-row items-center justify-center space-x-2 text-xs font-bold text-primary cursor-pointer"
      >
        <VueIcons name="chevron-left" class="w-6 h-6" />
        <span>Previous</span>
      </button>
      <button
        v-if="!loading"
        type="submit"
        class="bg-primary text-white text-xs font-semibold rounded-md px-5 py-2 cursor-pointer"
      >
        Next
      </button>
      <SpinLoader v-else :text="'Please Wait'" />
    </div>
  </form>
</template>

<script>
import Verte from "verte";
import html2canvas from "html2canvas";
import { mapMutations, mapGetters } from "vuex";

import "verte/dist/verte.css";
import VueIcons from "@/utils/VueIcons.vue";
import SpinLoader from "@/utils/SpinLoader.vue";

export default {
  name: "BackComForm",
  components: {
    Verte,
    VueIcons,
    SpinLoader,
  },
  data() {
    return {
      loading: false,
      backFormInput: {
        bgColor: "#FFFFFF",
        fontColor: "#000000",
        sex: "m",
        height: "",
        chest: "",
        waist: "",
        hip: "",
        eyes: "",
        hair: "",
        shoe: "",
        other: "",
        age: "",
      },
    };
  },
  computed: {
    ...mapGetters([
      "getComBorder",
      "getComOrientation",
      "getComFrontFormInput",
      "getActiveBackPTemplate",
      "getActiveBackLTemplate",
      "getBackCroppedComImageOne",
      "getBackCroppedComImageTwo",
      "getBackCroppedComImageSix",
      "getBackCroppedComImageFour",
      "getBackCroppedComImageFive",
      "getBackCroppedComImageThree",
      "getBackCroppedComImageSeven",
      "getBackCroppedComImageEight",
      "getBackCroppedComImageNine",
    ]),
  },
  methods: {
    ...mapMutations(["setComBackPdf", "setComBackFormInput", "setComBorder"]),
    toFrontForm(e) {
      e.preventDefault();
      this.$router.currentRoute.fullPath.includes("/comp-cards")
        ? this.$router.push("/comp-cards/fc")
        : this.$router.push("/headshots/fh");
    },
    async onSubmit(e) {
      e.preventDefault();
      document.getElementById("back-com-form").scrollTop = 0;
      await this.setComBorder({
        comBorder: false,
      });
      if (this.getComOrientation === "p") {
        if (
          this.getActiveBackPTemplate === 0 ||
          this.getActiveBackPTemplate === 1 ||
          this.getActiveBackPTemplate === 5 ||
          this.getActiveBackPTemplate === 13 ||
          this.getActiveBackPTemplate === 14
        ) {
          if (
            this.getBackCroppedComImageOne === "" ||
            this.getBackCroppedComImageTwo === "" ||
            this.getBackCroppedComImageThree === "" ||
            this.getBackCroppedComImageFour === ""
          ) {
            this.$toasted.show("Please Upload an Image Before Proceeding");
          } else {
            const image1 = document.getElementById("comBack");
            this.loading = true;
            await html2canvas(image1, {
              orientation: "portrait",
              unit: "in",
              format: [5.625, 8.625],
              scale: 4,
            }).then((canvas) => {
              this.setComBackPdf({
                comBackPdf: canvas.toDataURL("image/jpeg"),
              });
            });
            this.loading = false;
            this.$router.currentRoute.fullPath.includes("/comp-cards")
              ? this.$router.push("/comp-cards/cc")
              : this.$router.push("/headshots/ch");
          }
        } else if (
          this.getActiveBackPTemplate === 2 ||
          this.getActiveBackPTemplate === 7 ||
          this.getActiveBackPTemplate === 15
        ) {
          if (
            this.getBackCroppedComImageOne === "" ||
            this.getBackCroppedComImageTwo === "" ||
            this.getBackCroppedComImageThree === "" ||
            this.getBackCroppedComImageFour === "" ||
            this.getBackCroppedComImageFive === "" ||
            this.getBackCroppedComImageSix === ""
          ) {
            this.$toasted.show("Please Upload an Image Before Proceeding");
          } else {
            const image1 = document.getElementById("comBack");
            this.loading = true;
            await html2canvas(image1, {
              orientation: "portrait",
              unit: "in",
              format: [5.625, 8.625],
              scale: 4,
            }).then((canvas) => {
              this.setComBackPdf({
                comBackPdf: canvas.toDataURL("image/jpeg"),
              });
            });
            this.loading = false;
            this.$router.currentRoute.fullPath.includes("/comp-cards")
              ? this.$router.push("/comp-cards/cc")
              : this.$router.push("/headshots/ch");
          }
        } else if (
          this.getActiveBackPTemplate === 3 ||
          this.getActiveBackPTemplate === 11 ||
          this.getActiveBackPTemplate === 12
        ) {
          if (
            this.getBackCroppedComImageOne === "" ||
            this.getBackCroppedComImageTwo === "" ||
            this.getBackCropppedComImageThree === ""
          ) {
            this.$toasted.show("Please Upload an Image Before Proceeding");
          } else {
            const image1 = document.getElementById("comBack");
            this.loading = true;
            await html2canvas(image1, {
              orientation: "portrait",
              unit: "in",
              format: [5.625, 8.625],
              scale: 4,
            }).then((canvas) => {
              this.setComBackPdf({
                comBackPdf: canvas.toDataURL("image/jpeg"),
              });
            });
            this.loading = false;
            this.$router.currentRoute.fullPath.includes("/comp-cards")
              ? this.$router.push("/comp-cards/cc")
              : this.$router.push("/headshots/ch");
          }
        } else if (
          this.getActiveBackPTemplate === 4 ||
          this.getActiveBackPTemplate === 8 ||
          this.getActiveBackPTemplate === 9 ||
          this.getActiveBackPTemplate === 10
        ) {
          if (
            this.getBackCroppedComImageOne === "" ||
            this.getBackCroppedComImageTwo === ""
          ) {
            this.$toasted.show("Please Upload an Image Before Proceeding");
          } else {
            const image1 = document.getElementById("comBack");
            this.loading = true;
            await html2canvas(image1, {
              orientation: "portrait",
              unit: "in",
              format: [5.625, 8.625],
              scale: 4,
            }).then((canvas) => {
              this.setComBackPdf({
                comBackPdf: canvas.toDataURL("image/jpeg"),
              });
            });
            this.loading = false;
            this.$router.currentRoute.fullPath.includes("/comp-cards")
              ? this.$router.push("/comp-cards/cc")
              : this.$router.push("/headshots/ch");
          }
        } else if (this.getActiveBackPTemplate === 6) {
          if (
            this.getBackCroppedComImageOne === "" ||
            this.getBackCroppedComImageTwo === "" ||
            this.getBackCroppedComImageThree === "" ||
            this.getBackCroppedComImageFour === "" ||
            this.getBackCroppedComImageFive === ""
          ) {
            this.$toasted.show("Please Upload an Image Before Proceeding");
          } else {
            const image1 = document.getElementById("comBack");
            this.loading = true;
            await html2canvas(image1, {
              orientation: "portrait",
              unit: "in",
              format: [5.625, 8.625],
              scale: 4,
            }).then((canvas) => {
              this.setComBackPdf({
                comBackPdf: canvas.toDataURL("image/jpeg"),
              });
            });
            this.loading = false;
            this.$router.currentRoute.fullPath.includes("/comp-cards")
              ? this.$router.push("/comp-cards/cc")
              : this.$router.push("/headshots/ch");
          }
        } else if (this.getActiveBackPTemplate === 16) {
          if (
            this.getBackCroppedComImageOne === "" ||
            this.getBackCroppedComImageTwo === "" ||
            this.getBackCroppedComImageThree === "" ||
            this.getBackCroppedComImageFour === "" ||
            this.getBackCroppedComImageFive === "" ||
            this.getBackCroppedComImageSix === "" ||
            this.getBackCroppedComImageSeven === ""
          ) {
            this.$toasted.show("Please Upload an Image Before Proceeding");
          } else {
            const image1 = document.getElementById("comBack");
            this.loading = true;
            await html2canvas(image1, {
              orientation: "portrait",
              unit: "in",
              format: [5.625, 8.625],
              scale: 4,
            }).then((canvas) => {
              this.setComBackPdf({
                comBackPdf: canvas.toDataURL("image/jpeg"),
              });
            });
            this.loading = false;
            this.$router.currentRoute.fullPath.includes("/comp-cards")
              ? this.$router.push("/comp-cards/cc")
              : this.$router.push("/headshots/ch");
          }
        } else if (this.getActiveBackPTemplate === 17) {
          if (
            this.getBackCroppedComImageOne === "" ||
            this.getBackCroppedComImageTwo === "" ||
            this.getBackCroppedComImageThree === "" ||
            this.getBackCroppedComImageFour === "" ||
            this.getBackCroppedComImageFive === "" ||
            this.getBackCroppedComImageSix === "" ||
            this.getBackCroppedComImageSeven === "" ||
            this.getBackCroppedComImageEight === ""
          ) {
            this.$toasted.show("Please Upload an Image Before Proceeding");
          } else {
            const image1 = document.getElementById("comBack");
            this.loading = true;
            await html2canvas(image1, {
              orientation: "portrait",
              unit: "in",
              format: [5.625, 8.625],
              scale: 4,
            }).then((canvas) => {
              this.setComBackPdf({
                comBackPdf: canvas.toDataURL("image/jpeg"),
              });
            });
            this.loading = false;
            this.$router.currentRoute.fullPath.includes("/comp-cards")
              ? this.$router.push("/comp-cards/cc")
              : this.$router.push("/headshots/ch");
          }
        } else {
          if (
            this.getBackCroppedComImageOne === "" ||
            this.getBackCroppedComImageTwo === "" ||
            this.getBackCroppedComImageThree === "" ||
            this.getBackCroppedComImageFour === "" ||
            this.getBackCroppedComImageFive === "" ||
            this.getBackCroppedComImageSix === "" ||
            this.getBackCroppedComImageSeven === "" ||
            this.getBackCroppedComImageEight === "" ||
            this.getBackCroppedComImageNine === ""
          ) {
            this.$toasted.show("Please Upload an Image Before Proceeding");
          } else {
            const image1 = document.getElementById("comBack");
            this.loading = true;
            await html2canvas(image1, {
              orientation: "portrait",
              unit: "in",
              format: [5.625, 8.625],
              scale: 4,
            }).then((canvas) => {
              this.setComBackPdf({
                comBackPdf: canvas.toDataURL("image/jpeg"),
              });
            });
            this.loading = false;
            this.$router.currentRoute.fullPath.includes("/comp-cards")
              ? this.$router.push("/comp-cards/cc")
              : this.$router.push("/headshots/ch");
          }
        }
      } else {
        if (this.getActiveBackLTemplate === 0) {
          if (
            this.getBackCroppedComImageOne === "" ||
            this.getBackCroppedComImageTwo === "" ||
            this.getBackCroppedComImageThree === "" ||
            this.getBackCroppedComImageFour === ""
          ) {
            this.$toasted.show("Please Upload an Image Before Proceeding");
          } else {
            const image1 = document.getElementById("comBack");
            this.loading = true;
            await html2canvas(image1, {
              orientation: "landscape",
              unit: "in",
              format: [8.625, 5.625],
              scale: 4,
            }).then((canvas) => {
              this.setComBackPdf({
                comBackPdf: canvas.toDataURL("image/jpeg"),
              });
            });
            this.loading = false;
            this.$router.currentRoute.fullPath.includes("/comp-cards")
              ? this.$router.push("/comp-cards/cc")
              : this.$router.push("/headshots/ch");
          }
        } else if (this.getActiveBackLTemplate === 1) {
          if (
            this.getBackCroppedComImageOne === "" ||
            this.getBackCroppedComImageTwo === "" ||
            this.getBackCroppedComImageThree === ""
          ) {
            this.$toasted.show("Please Upload an Image Before Proceeding");
          } else {
            const image1 = document.getElementById("comBack");
            this.loading = true;
            await html2canvas(image1, {
              orientation: "landscape",
              unit: "in",
              format: [8.625, 5.625],
              scale: 4,
            }).then((canvas) => {
              this.setComBackPdf({
                comBackPdf: canvas.toDataURL("image/jpeg"),
              });
            });
            this.loading = false;
            this.$router.currentRoute.fullPath.includes("/comp-cards")
              ? this.$router.push("/comp-cards/cc")
              : this.$router.push("/headshots/ch");
          }
        } else {
          if (this.getBackCroppedComImageOne === "") {
            this.$toasted.show("Please Upload an Image Before Proceeding");
          } else {
            const image1 = document.getElementById("comBack");
            this.loading = true;
            await html2canvas(image1, {
              orientation: "landscape",
              unit: "in",
              format: [8.625, 5.625],
              scale: 4,
            }).then((canvas) => {
              this.setComBackPdf({
                comBackPdf: canvas.toDataURL("image/jpeg"),
              });
            });
            this.loading = false;
            this.$router.currentRoute.fullPath.includes("/comp-cards")
              ? this.$router.push("/comp-cards/cc")
              : this.$router.push("/headshots/ch");
          }
        }
      }
    },
  },
  watch: {
    backFormInput: {
      handler: function () {
        this.setComBackFormInput({ comBackFormInput: this.backFormInput });
        localStorage.setItem("backCom", JSON.stringify(this.backFormInput));
      },
      deep: true,
    },
  },
  created() {
    this.setComBorder({
      comBorder: true,
    });
    setTimeout(() => {
      let backCom = JSON.parse(localStorage.getItem("backCom"));
      if (backCom.bgColor !== "#ffffff") {
        Object.assign(
          this.backFormInput,
          JSON.parse(localStorage.getItem("backCom"))
        );
      } else {
        this.backFormInput.bgColor = this.getComFrontFormInput.bgColor;
        this.backFormInput.fontColor = this.getComFrontFormInput.nameColor;
      }
    }, 500);
  },
};
</script>
