<template>
  <div class="relative">
    <NoticeVue />
    <div
      id="main"
      class="relative p-[12.5px] z-10"
      :class="{ 'border border-indigo-600': border }"
      :style="[
        portrait.main,
        {
          backgroundColor: getComFrontFormInput.bgColor,
        },
      ]"
    >
      <img
        v-if="getFrontCroppedComImage"
        :src="getFrontCroppedComImage"
        alt="front-image"
        id="image"
        class="z-[-1]"
        :style="portrait.img"
      />
      <img
        v-else
        alt="default"
        id="preview-image"
        class="z-[-1]"
        src="@/assets/img/default.png"
        :style="portrait.img"
      />
      <div
        class="w-[100%] h-full flex flex-row z-30"
        :class="{ 'border border-red-600': border }"
      >
        <div
          id="text"
          class="flex flex-col rotate-[-90deg]"
          :style="[
            getComOrientation === 'p' ? portrait.text : landscape.text,
            {
              fontFamily: getComFrontFormInput.font,
              textAlign: getComFrontFormInput.textAlign,
              height: `calc(${nameHeight()}+${tagHeight()})`,
            },
          ]"
        >
          <span
            id="name"
            :style="[
              getComOrientation === 'p' ? portrait.h3 : landscape.h3,
              {
                color: getComFrontFormInput.nameColor,
                fontSize: getComFrontFormInput.nameSize + 'px',
                lineHeight: nameHeight(),
              },
            ]"
          >
            {{
              getComFrontFormInput.name === ""
                ? "Your Name Here"
                : getComFrontFormInput.name
            }}
          </span>
          <span
            id="tag"
            :style="[
              getComOrientation === 'p' ? portrait.p : landscape.p,
              {
                color: getComFrontFormInput.tagColor,
                fontSize: getComFrontFormInput.tagSize + 'px',
                lineHeight: getComFrontFormInput.tagSize + 'px',
              },
            ]"
          >
            {{ getComFrontFormInput.tagline }}
          </span>
        </div>
      </div>
    </div>
    <!-- Watermark -->
    <WaterMark />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import NoticeVue from "@/utils/Notice.vue";
import WaterMark from "@/utils/WaterMark.vue";

export default {
  name: "BackTemplateNine",
  components: {
    NoticeVue,
    WaterMark,
  },
  data() {
    return {
      border: true,
      input: {},
      portrait: {
        main: {
          width: "422px",
          height: "647px",
        },
        img: {
          width: "339.5px",
          height: "560.5px",
          "object-fit": "cover",
          position: "absolute",
          left: 0,
          top: "50%",
          transform: "translateY(-50%)",
        },
        text: {
          width: "560.5px",
          height: "86.5px",
          position: "absolute",
          bottom: "45.5%",
          left: "19.5%",
        },
        h3: {
          width: "560.5px",
          "font-size": 36,
          "line-height": "43.25px",
        },
        p: {
          width: "560.5px",
          "font-size": 18,
          "line-height": "43.25px",
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "getComBorder",
      "getComOrientation",
      "getComFrontFormInput",
      "getFrontCroppedComImage",
    ]),
  },
  methods: {
    nameHeight() {
      let height = 0;
      if (this.input.tagline === "") {
        height = "86.5px";
      } else {
        height = this.input.nameSize + "px";
      }
      return height;
    },
    tagHeight() {
      let height = 0;
      if (this.input.name === "") {
        height = "86.5px";
      } else {
        height = this.input.tagSize + "px";
      }
      return height;
    },
  },
  watch: {
    input(newInput) {
      if (
        JSON.stringify(newInput) !== JSON.stringify(this.getComFrontFormInput)
      ) {
        this.input = this.getComFrontFormInput;
      }
    },
    getComBorder(newInput) {
      this.border = newInput;
    },
  },
  created() {
    this.border = this.getComBorder;
    this.input = this.getComFrontFormInput;
  },
};
</script>
