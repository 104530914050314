import Vue from "vue";
import VueRx from "vue-rx";
import Toasted from "vue-toasted";
import plugin from "@serializedowen/vue-img-watermark";
import VuejsClipper from "vuejs-clipper/dist/vuejs-clipper.umd.min";

import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import "./assets/css/tailwind.css";
import "vuejs-clipper/dist/vuejs-clipper.css";

Vue.config.productionTip = false;

Vue.use(VueRx);
Vue.use(plugin);
Vue.use(VuejsClipper);
Vue.use(Toasted, {
  icon: "warning",
  theme: "bubble",
  type: "error",
  position: "bottom-center",
  duration: 1500,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
