<!-- 
  Radio Toggle by Mushood Hanif
  ==============================
  - Highly Compatible and Portable Toggle Component using Tailwind
  - To Use, Simply Import and Pass Your Toggle Value as Prop
  - Please Create this Component in an Exclusive <div> and Place the Trigger onClick on that <div>
-->

<template>
  <div class="col-span-1 flex flex-col cursor-pointer text-white">
    <div
      class="mx-auto items-center w-16 h-8 bg-gray-400 rounded-2xl p-1 flex flex-row space-x-1"
      :class="{
        'bg-[#4E67EB]': trigger,
      }"
    >
      <div
        class="w-6 h-6 bg-white rounded-2xl"
        :class="{ 'order-1': trigger }"
      ></div>
      <div>
        <p class="font-semibold mr-1">
          {{ trigger ? "YES" : "NO" }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioToggle",
  props: ["trigger"],
};
</script>
