<template>
  <img
    oncontextmenu="return false;"
    src="@/assets/img/watermark.png"
    alt="watermark"
    class="w-52"
    :style="absolute"
  />
</template>

<script>
export default {
  name: "WaterMark",
  data() {
    return {
      absolute: {
        position: "absolute",
        bottom: "50%",
        left: "50%",
        transform: "translate(-50%)",
      },
    };
  },
};
</script>
