var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col",style:([
    _vm.styles.text,
    {
      fontFamily: _vm.data.font,
      textAlign: _vm.data.textAlign,
      height: `calc(${_vm.nameHeight}+${_vm.tagHeight})`,
      backgroundColor:
        _vm.activeHeadTemplate === 3 ? 'transparent' : _vm.data.bgColor,
    },
  ]),attrs:{"id":"text"}},[_c('span',{style:([
      _vm.styles.h3,
      {
        color: _vm.data.nameColor,
        fontSize: _vm.data.nameSize + 'px',
        lineHeight: _vm.nameHeight,
      },
    ]),attrs:{"id":"name"}},[_vm._v(" "+_vm._s(_vm.data.name === "" ? "Your Name Here" : _vm.data.name)+" ")]),(_vm.data.tagline !== '')?_c('span',{staticClass:"mt-[10px]",style:([
      _vm.styles.p,
      {
        color: _vm.data.tagColor,
        fontSize: _vm.data.tagSize + 'px',
        lineHeight: _vm.data.tagSize + 'px',
      },
    ]),attrs:{"id":"tag"}},[_vm._v(" "+_vm._s(_vm.data.tagline)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }