<template>
  <div
    :style="{
      height: `calc(${text * 2}px)`,
    }"
  >
    <ul
      class="flex flex-wrap items-center justify-center"
      :class="{ 'mt-[10px]': !text }"
      :style="{
        color: data.fontColor,
        flexDirection: direction ? direction : 'row',
        width: width + 'px',
        fontSize: text ? text : '15px',
      }"
    >
      <li v-if="(data.sex === 'k' || data.sex === 'a') && data.age">
        <span class="font-bold">Age:&nbsp;</span>{{ data.age }}&nbsp;•&nbsp;
      </li>
      <li v-if="data.height">
        <span class="font-bold">Height:&nbsp;</span
        >{{ data.height }}&nbsp;•&nbsp;
      </li>
      <li v-if="data.sex !== 'k' && data.chest">
        <span class="font-bold"
          >{{ data.sex === "m" ? "Chest" : "Bust" }}:&nbsp;</span
        >{{ data.chest }}&nbsp;•&nbsp;
      </li>
      <li v-if="data.sex !== 'k' && data.waist">
        <span class="font-bold">Waist:&nbsp;</span>{{ data.waist }}&nbsp;•&nbsp;
      </li>
      <li v-if="data.sex !== 'k' && data.hip">
        <span class="font-bold">Hip:&nbsp;</span>{{ data.hip }}&nbsp;•&nbsp;
      </li>
      <li v-if="data.eyes">
        <span class="font-bold">Eyes:&nbsp;</span>{{ data.eyes }}&nbsp;•&nbsp;
      </li>
      <li v-if="data.hair">
        <span class="font-bold">Hair:&nbsp;</span>{{ data.hair }}&nbsp;•&nbsp;
      </li>
      <li v-if="data.shoe">
        <span class="font-bold">Shoe:&nbsp;</span>{{ data.shoe }}&nbsp;&nbsp;
      </li>
    </ul>
    <p
      v-if="data.other"
      class="text-center text-wrap"
      :class="!text ? 'mt-[10px]' : 'mr-1 '"
      :style="{
        color: data.fontColor,
        width: width,
        fontSize: text ? text : '15px',
      }"
    >
      {{ data.other }}
    </p>
  </div>
</template>

<script>
export default {
  name: "UserInfo",
  props: {
    width: Number,
    text: String,
    direction: String,
    data: Object,
  },
};
</script>
