<template>
  <div class="relative">
    <NoticeVue />
    <div
      id="comBack"
      :style="{ backgroundColor: getComBackFormInput.bgColor }"
      class="w-[422px] h-[647px] p-[12.5px]"
      :class="{ 'border border-indigo-600': border }"
    >
      <!-- Width Controller -->
      <div
        class="w-[398px] h-[623px] p-[10px]"
        :class="{ 'border border-red-600': border }"
      >
        <!-- Hidden File Type Input -->
        <input
          type="file"
          id="imageUpload"
          accept="image/png, image/jpeg"
          class="hidden"
          @change="onFileChange"
        />
        <!-- Images -->
        <div class="w-[378px] h-[603px] grid grid-rows-2 gap-[10px]">
          <!-- Images -->
          <div class="w-[378px] h-[296.5px] grid grid-cols-2 gap-[10px]">
            <img
              :src="
                getBackCroppedComImageOne
                  ? getBackCroppedComImageOne
                  : require('@/assets/img/portraitDefault.png')
              "
              alt="img1"
              class="w-[184px] h-[296.5px] object-cover cursor-pointer"
              @click="fakeClick"
            />
            <img
              :src="
                getBackCroppedComImageTwo
                  ? getBackCroppedComImageTwo
                  : require('@/assets/img/portraitDefault.png')
              "
              alt="img2"
              class="w-[184px] h-[296.5px] object-cover cursor-pointer"
              @click="fakeClick"
            />
          </div>
          <!-- Text -->
          <div
            class="row-span-1 w-[378px] h-[296.5px] flex flex-col items-center justify-center"
          >
            <ul
              class="text-center"
              :style="{
                color: getComBackFormInput.fontColor,
              }"
            >
              <li
                v-if="
                  (getComBackFormInput.sex === 'k' ||
                    getComBackFormInput.sex === 'a') &&
                  getComBackFormInput.age
                "
              >
                <span class="font-bold">Age:</span>{{ getComBackFormInput.age }}
              </li>
              <li v-if="getComBackFormInput.height">
                <span class="font-bold">Height:</span
                >{{ getComBackFormInput.height }}
              </li>
              <li
                v-if="
                  getComBackFormInput.sex !== 'k' && getComBackFormInput.chest
                "
              >
                <span class="font-bold"
                  >{{
                    getComBackFormInput.sex === "m" ? "Chest" : "Bust"
                  }}:</span
                >{{ getComBackFormInput.chest }}
              </li>
              <li
                v-if="
                  getComBackFormInput.sex !== 'k' && getComBackFormInput.waist
                "
              >
                <span class="font-bold">Waist:</span
                >{{ getComBackFormInput.waist }}
              </li>
              <li
                v-if="
                  getComBackFormInput.sex !== 'k' && getComBackFormInput.hip
                "
              >
                <span class="font-bold">Hip:</span>{{ getComBackFormInput.hip }}
              </li>
              <li v-if="getComBackFormInput.eyes">
                <span class="font-bold">Eyes:</span
                >{{ getComBackFormInput.eyes }}
              </li>
              <li v-if="getComBackFormInput.hair">
                <span class="font-bold">Hair:</span
                >{{ getComBackFormInput.hair }}
              </li>
              <li v-if="getComBackFormInput.shoe">
                <span class="font-bold">Shoe:</span
                >{{ getComBackFormInput.shoe }}
              </li>
            </ul>
            <p
              v-if="getComBackFormInput.other"
              class="mt-[70px] text-[10px] h-[12px] text-center text-wrap overflow-hidden col-span-2"
              :style="{
                color: getComBackFormInput.fontColor,
                lineHeight: '12px',
              }"
            >
              {{ getComBackFormInput.other }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <WaterMark />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import NoticeVue from "@/utils/Notice.vue";
import WaterMark from "@/utils/WaterMark.vue";

export default {
  name: "ComBackTemplateNine",
  data() {
    return {
      border: true,
      image: "",
    };
  },
  components: {
    NoticeVue,
    WaterMark,
  },
  computed: {
    ...mapGetters([
      "getComBorder",
      "getComBackFormInput",
      "getBackCroppedComImageOne",
      "getBackCroppedComImageTwo",
    ]),
  },
  methods: {
    ...mapMutations(["setBackComImage"]),
    fakeClick(e) {
      this.image = e.target.alt;
      document.getElementById("imageUpload").click();
    },
    onFileChange(e) {
      if (this.image === "img1") {
        this.setBackComImage({
          id: 1,
          backComImage: URL.createObjectURL(e.target.files[0]),
        });
      } else {
        this.setBackComImage({
          id: 2,
          backComImage: URL.createObjectURL(e.target.files[0]),
        });
      }
      e.target.value = null;
    },
  },
  watch: {
    getComBorder(newInput) {
      this.border = newInput;
    },
  },
  created() {
    this.border = this.getComBorder;
  },
};
</script>
