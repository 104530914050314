export const comcard = {
  state: {
    comBorder: true,
    comFrontFormInput: {
      name: "Your Name Here",
      tagline: "Your Tagline Here",
      bgColor: "#FFFFFF",
      font: "Abril Fatface, cursive",
      textAlign: "left",
      nameSize: "36",
      nameColor: "#000000",
      tagSize: "18",
      tagColor: "#000000",
    },
    comBackFormInput: {
      bgColor: "#FFFFFF",
      fontColor: "#000000",
      sex: "m",
      height: "181",
      chest: "46",
      waist: "38",
      hip: "38",
      eyes: "Brown",
      hair: "Black",
      shoe: "44",
      other: "none",
      age: "25",
    },
    activeFrontPTemplate: 0,
    activeFrontLTemplate: 0,
    activeBackPTemplate: 0,
    activeBackLTemplate: 0,
    frontComImage: "",
    frontCroppedComImage: "",
    backComImageOne: "",
    backCroppedComImageOne: "",
    backComImageTwo: "",
    backCroppedComImageTwo: "",
    backComImageThree: "",
    backCroppedComImageThree: "",
    backComImageFour: "",
    backCroppedComImageFour: "",
    backComImageFive: "",
    backCroppedComImageFive: "",
    backComImageSix: "",
    backCroppedComImageSix: "",
    backComImageSeven: "",
    backCroppedComImageSeven: "",
    backComImageEight: "",
    backCroppedComImageEight: "",
    backComImageNine: "",
    backCroppedComImageNine: "",
    comOrientation: "p",
    comFrontPdf: "",
    comBackPdf: "",
    finalComPdf: "",
  },
  getters: {
    getComFrontFormInput(state) {
      return state.comFrontFormInput;
    },
    getComBackFormInput(state) {
      return state.comBackFormInput;
    },
    getActiveFrontPTemplate(state) {
      return state.activeFrontPTemplate;
    },
    getActiveFrontLTemplate(state) {
      return state.activeFrontLTemplate;
    },
    getActiveBackPTemplate(state) {
      return state.activeBackPTemplate;
    },
    getActiveBackLTemplate(state) {
      return state.activeBackLTemplate;
    },
    getFrontComImage(state) {
      return state.frontComImage;
    },
    getFrontCroppedComImage(state) {
      return state.frontCroppedComImage;
    },
    getBackComImageOne(state) {
      return state.backComImageOne;
    },
    getBackComImageTwo(state) {
      return state.backComImageTwo;
    },
    getBackComImageThree(state) {
      return state.backComImageThree;
    },
    getBackComImageFour(state) {
      return state.backComImageFour;
    },
    getBackComImageFive(state) {
      return state.backComImageFive;
    },
    getBackComImageSix(state) {
      return state.backComImageSix;
    },
    getBackComImageSeven(state) {
      return state.backComImageSeven;
    },
    getBackComImageEight(state) {
      return state.backComImageEight;
    },
    getBackComImageNine(state) {
      return state.backComImageNine;
    },
    getBackCroppedComImageOne(state) {
      return state.backCroppedComImageOne;
    },
    getBackCroppedComImageTwo(state) {
      return state.backCroppedComImageTwo;
    },
    getBackCroppedComImageThree(state) {
      return state.backCroppedComImageThree;
    },
    getBackCroppedComImageFour(state) {
      return state.backCroppedComImageFour;
    },
    getBackCroppedComImageFive(state) {
      return state.backCroppedComImageFive;
    },
    getBackCroppedComImageSix(state) {
      return state.backCroppedComImageSix;
    },
    getBackCroppedComImageSeven(state) {
      return state.backCroppedComImageSeven;
    },
    getBackCroppedComImageEight(state) {
      return state.backCroppedComImageEight;
    },
    getBackCroppedComImageNine(state) {
      return state.backCroppedComImageNine;
    },
    getComOrientation(state) {
      return state.comOrientation;
    },
    getComFrontPdf(state) {
      return state.comFrontPdf;
    },
    getComBackPdf(state) {
      return state.comBackPdf;
    },
    getFinalComPdf(state) {
      return state.finalComPdf;
    },
    getComBorder(state) {
      return state.comBorder;
    },
  },
  mutations: {
    setComFrontFormInput(state, payload) {
      Object.assign(state.comFrontFormInput, payload.comFrontFormInput);
    },
    setComBackFormInput(state, payload) {
      Object.assign(state.comBackFormInput, payload.comBackFormInput);
    },
    setActiveFrontPTemplate(state, payload) {
      state.activeFrontPTemplate = payload.activeFrontPTemplate;
    },
    setActiveFrontLTemplate(state, payload) {
      state.activeFrontLTemplate = payload.activeFrontLTemplate;
    },
    setActiveBackPTemplate(state, payload) {
      state.activeBackPTemplate = payload.activeBackPTemplate;
    },
    setActiveBackLTemplate(state, payload) {
      state.activeBackLTemplate = payload.activeBackLTemplate;
    },
    setFrontComImage(state, payload) {
      state.frontComImage = payload.frontComImage;
    },
    setFrontCroppedComImage(state, payload) {
      state.frontCroppedComImage = payload.frontCroppedComImage;
    },
    setBackComImage(state, payload) {
      switch (payload.id) {
        case 1:
          state.backComImageOne = payload.backComImage;
          break;
        case 2:
          state.backComImageTwo = payload.backComImage;
          break;
        case 3:
          state.backComImageThree = payload.backComImage;
          break;
        case 4:
          state.backComImageFour = payload.backComImage;
          break;
        case 5:
          state.backComImageFive = payload.backComImage;
          break;
        case 6:
          state.backComImageSix = payload.backComImage;
          break;
        case 7:
          state.backComImageSeven = payload.backComImage;
          break;
        case 8:
          state.backComImageEight = payload.backComImage;
          break;
        case 9:
          state.backComImageNine = payload.backComImage;
          break;
        default:
          state.backComImageOne = payload.backComImage;
      }
    },
    setBackCroppedComImage(state, payload) {
      switch (payload.id) {
        case 1:
          state.backCroppedComImageOne = payload.backCroppedComImage;
          break;
        case 2:
          state.backCroppedComImageTwo = payload.backCroppedComImage;
          break;
        case 3:
          state.backCroppedComImageThree = payload.backCroppedComImage;
          break;
        case 4:
          state.backCroppedComImageFour = payload.backCroppedComImage;
          break;
        case 5:
          state.backCroppedComImageFive = payload.backCroppedComImage;
          break;
        case 6:
          state.backCroppedComImageSix = payload.backCroppedComImage;
          break;
        case 7:
          state.backCroppedComImageSeven = payload.backCroppedComImage;
          break;
        case 8:
          state.backCroppedComImageEight = payload.backCroppedComImage;
          break;
        case 9:
          state.backCroppedComImageNine = payload.backCroppedComImage;
          break;
        default:
          state.backCroppedComImageOne = payload.backCroppedComImage;
      }
    },
    setBackPdf(state, payload) {
      state.backPdf = payload.backPdf;
    },
    setComOrientation(state, payload) {
      state.comOrientation = payload.comOrientation;
    },
    setComFrontPdf(state, payload) {
      state.comFrontPdf = payload.comFrontPdf;
    },
    setComBackPdf(state, payload) {
      state.comBackPdf = payload.comBackPdf;
    },
    setFinalComPdf(state, payload) {
      state.finalComPdf = payload.finalComPdf;
    },
    setComBorder(state, payload) {
      state.comBorder = payload.comBorder;
    },
  },
};
