<template>
  <ul
    class="flex flex-col items-center justify-center h-screen space-y-5 text-xs text-gray-600 font-semibold px-5"
  >
    <li
      class="flex flex-col items-center justify-center"
      :class="{
        'text-primary': activeForm === 'front',
      }"
    >
      <VueIcons name="pencil" class="w-4 h-4" />
      <span>Front</span>
    </li>
    <li
      class="flex flex-col items-center justify-center"
      :class="{
        'text-primary': activeForm === 'back',
      }"
    >
      <VueIcons name="pencil" class="w-4 h-4" />
      <span>Back</span>
    </li>
    <li
      class="flex flex-col items-center justify-center"
      :class="{
        'text-primary': activeForm === 'checkout',
      }"
    >
      <VueIcons name="cart" class="w-6 h-6" />
      <span>Checkout</span>
    </li>
  </ul>
</template>

<script>
import VueIcons from "@/utils/VueIcons.vue";

export default {
  name: "LeftBar",
  components: {
    VueIcons,
  },
  data() {
    return {
      activeForm: "front",
    };
  },
  watch: {
    $route(newRoute) {
      if (newRoute.fullPath.includes("/f")) {
        this.activeForm = "front";
      } else if (newRoute.fullPath.includes("/b")) {
        this.activeForm = "back";
      } else {
        this.activeForm = "checkout";
      }
    },
  },
  created() {
    if (this.$router.currentRoute.fullPath.includes("/f")) {
      this.activeForm = "front";
    } else if (this.$router.currentRoute.fullPath.includes("/b")) {
      this.activeForm = "back";
    } else {
      this.activeForm = "checkout";
    }
  },
};
</script>
