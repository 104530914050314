export const headshot = {
  state: {
    headBorder: true,
    headFrontFormInput: {
      name: "Your Name Here",
      tagline: "Your Tagline Here",
      bgColor: "#000000",
      font: "Abril Fatface, cursive",
      textAlign: "left",
      nameSize: "36",
      nameColor: "#FFFFFF",
      tagSize: "18",
      tagColor: "#FFFFFF",
    },
    activeHeadTemplate: 0,
    frontHeadImage: "",
    frontCroppedHeadImage: "",
    backHeadImage: "",
    backCroppedHeadImage: "",
    frontPdf: "",
    backPdf: "",
    // backCroppedPdf: "",
    headOrientation: "p",
    finalHeadPdf: "",
  },
  getters: {
    getHeadFrontFormInput(state) {
      return state.headFrontFormInput;
    },
    getActiveHeadTemplate(state) {
      return state.activeHeadTemplate;
    },
    getFrontHeadImage(state) {
      return state.frontHeadImage;
    },
    getFrontCroppedHeadImage(state) {
      return state.frontCroppedHeadImage;
    },
    getBackHeadImage(state) {
      return state.backHeadImage;
    },
    getBackCroppedHeadImage(state) {
      return state.backCroppedHeadImage;
    },
    getHeadOrientation(state) {
      return state.headOrientation;
    },
    getFrontPdf(state) {
      return state.frontPdf;
    },
    getBackPdf(state) {
      return state.backPdf;
    },
    getFinalHeadPdf(state) {
      return state.finalHeadPdf;
    },
    getHeadBorder(state) {
      return state.headBorder;
    },
  },
  mutations: {
    setHeadFrontFormInput(state, payload) {
      Object.assign(state.headFrontFormInput, payload.headFrontFormInput);
    },
    setActiveHeadTemplate(state, payload) {
      state.activeHeadTemplate = payload.activeHeadTemplate;
    },
    setFrontHeadImage(state, payload) {
      state.frontHeadImage = payload.frontHeadImage;
    },
    setFrontCroppedHeadImage(state, payload) {
      state.frontCroppedHeadImage = payload.frontCroppedHeadImage;
    },
    setBackHeadImage(state, payload) {
      state.backHeadImage = payload.backHeadImage;
    },
    setBackCroppedHeadImage(state, payload) {
      state.backCroppedHeadImage = payload.backCroppedHeadImage;
    },
    setFrontPdf(state, payload) {
      state.frontPdf = payload.frontPdf;
    },
    setBackPdf(state, payload) {
      state.backPdf = payload.backPdf;
    },
    setHeadOrientation(state, payload) {
      state.headOrientation = payload.headOrientation;
    },
    setFinalHeadPdf(state, payload) {
      state.finalHeadPdf = payload.finalHeadPdf;
    },
    setHeadBorder(state, payload) {
      state.headBorder = payload.headBorder;
    },
  },
};
