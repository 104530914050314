import Vue from "vue";
import VueRouter from "vue-router";

// Layouts
import MainView from "../views/MainView.vue";

// Forms
import FrontHeadForm from "../components/FrontHeadForm.vue";
import FrontComForm from "../components/FrontComForm.vue";
import BackComForm from "../components/BackComForm.vue";
import BackHeadForm from "../components/BackHeadForm.vue";
import CheckoutForm from "../components/common/CheckoutForm.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/headshots",
  },
  {
    path: "/headshots",
    name: "headshots",
    redirect: "headshots/fh",
    component: MainView,
    children: [
      {
        path: "/headshots/fh",
        name: "fh",
        component: FrontHeadForm,
        props: true,
      },
      {
        path: "/headshots/bh",
        name: "bh",
        component: BackHeadForm,
        props: true,
      },
      {
        path: "/headshots/ch",
        name: "ch",
        component: CheckoutForm,
        props: true,
      },
    ],
  },
  {
    path: "/comp-cards",
    name: "comp-card",
    component: MainView,
    redirect: "comp-cards/fc",
    children: [
      {
        path: "/comp-cards/fc",
        name: "fc",
        component: FrontComForm,
        props: true,
      },
      {
        path: "/comp-cards/bc",
        name: "bc",
        component: BackComForm,
        props: true,
      },
      {
        path: "/comp-cards/cc",
        name: "cc",
        component: CheckoutForm,
        props: true,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
