<template>
  <p class="text-xs font-bold text-red-800">
    Please Note: Watermark will NOT be printed on the final product.
  </p>
</template>

<script>
export default {
  name: "NoticeVue",
};
</script>
