<template>
  <div class="relative">
    <NoticeVue />
    <div
      class="p-[12.5px] flex flex-col items-center justify-center"
      :class="{ 'border border-indigo-600': border }"
      id="main"
      :style="{ backgroundColor: getComFrontFormInput.bgColor }"
    >
      <div
        :class="{ 'border border-red-600': border }"
        :style="[
          portrait.main,
          {
            backgroundColor: getComFrontFormInput.bgColor,
          },
        ]"
      >
        <TemplateText
          :data="getComFrontFormInput"
          :styles="portrait"
          :nameHeight="nameHeight()"
          :tagHeight="tagHeight()"
        />
        <img
          v-if="getFrontCroppedComImage"
          :src="getFrontCroppedComImage"
          alt="front-image"
          id="image"
          :style="portrait.img"
        />
        <img
          v-else
          alt="default"
          id="preview-image"
          src="@/assets/img/default.png"
          :style="portrait.img"
        />
      </div>
    </div>
    <!-- Watermark -->
    <WaterMark />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import NoticeVue from "@/utils/Notice.vue";
import WaterMark from "@/utils/WaterMark.vue";
import TemplateText from "@/utils/TemplateText.vue";

export default {
  name: "BackTemplateSeven",
  components: {
    NoticeVue,
    WaterMark,
    TemplateText,
  },
  data() {
    return {
      border: true,
      input: {},
      portrait: {
        main: {
          width: "398px",
          height: "623px",
        },
        img: {
          width: "374px",
          height: "510px",
          margin: "0 12.5px 12.5px 12.5px",
          "object-fit": "cover",
          position: "absolute",
          // top: "105.5px",
        },
        text: {
          margin: "0 12.5px 5px 12.5px",
          width: "374px",
        },
        h3: {
          width: "374px",
          "font-size": 36,
        },
        p: {
          width: "374px",
          "font-size": 18,
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "getComBorder",
      "getComOrientation",
      "getComFrontFormInput",
      "getFrontCroppedComImage",
    ]),
  },
  methods: {
    nameHeight() {
      let height = 0;
      if (this.input.tagline === "") {
        height = "55.5px";
      } else {
        height = Number(this.input.nameSize) + "px";
      }
      return height;
    },
    tagHeight() {
      let height = 0;
      if (this.input.name === "") {
        height = "55.5px";
      } else {
        height = Number(this.input.tagSize) + "px";
      }
      return height;
    },
  },
  watch: {
    input(newInput) {
      if (
        JSON.stringify(newInput) !== JSON.stringify(this.getComFrontFormInput)
      ) {
        this.input = this.getComFrontFormInput;
      }
    },
    getComBorder(newInput) {
      this.border = newInput;
    },
  },
  created() {
    this.border = this.getComBorder;
    this.input = this.getComFrontFormInput;
  },
};
</script>
