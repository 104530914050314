var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('NoticeVue'),_c('div',{staticClass:"w-[422px] h-[647px] p-[12.5px]",class:{ 'border border-indigo-600': _vm.border },style:({ backgroundColor: _vm.getComBackFormInput.bgColor }),attrs:{"id":"comBack"}},[_c('div',{staticClass:"w-[398px] h-[623px] p-[10px]",class:{ 'border border-red-600': _vm.border }},[_c('input',{staticClass:"hidden",attrs:{"type":"file","id":"imageUpload","accept":"image/png, image/jpeg"},on:{"change":_vm.onFileChange}}),_c('div',{staticClass:"w-[378px] h-[485.5px] grid grid-cols-2 gap-[5px]"},[_c('img',{staticClass:"w-[186.5px] h-[240.25px] object-cover cursor-pointer",attrs:{"src":_vm.getBackCroppedComImageOne
              ? _vm.getBackCroppedComImageOne
              : require('@/assets/img/portraitDefault.png'),"alt":"img1"},on:{"click":_vm.fakeClick}}),_c('div',{staticClass:"w-[186.5px] h-[240.25px] grid grid-rows-2 gap-[5px]"},[_c('img',{staticClass:"w-[186.5px] h-[117.625px] object-cover cursor-pointer",attrs:{"src":_vm.getBackCroppedComImageTwo
                ? _vm.getBackCroppedComImageTwo
                : require('@/assets/img/landscapeDefault.png'),"alt":"img2"},on:{"click":_vm.fakeClick}}),_c('img',{staticClass:"w-[186.5px] h-[117.625px] object-cover cursor-pointer",attrs:{"src":_vm.getBackCroppedComImageThree
                ? _vm.getBackCroppedComImageThree
                : require('@/assets/img/landscapeDefault.png'),"alt":"img3"},on:{"click":_vm.fakeClick}})]),_c('div',{staticClass:"w-[378px] h-[240.25px] grid grid-cols-3 gap-[5px]"},[_c('img',{staticClass:"w-[124.3px] h-[240.25px] object-cover cursor-pointer",attrs:{"src":_vm.getBackCroppedComImageFour
                ? _vm.getBackCroppedComImageFour
                : require('@/assets/img/portraitDefault.png'),"alt":"img4"},on:{"click":_vm.fakeClick}}),_c('img',{staticClass:"w-[124.3px] h-[240.25px] object-cover cursor-pointer",attrs:{"src":_vm.getBackCroppedComImageFive
                ? _vm.getBackCroppedComImageFive
                : require('@/assets/img/portraitDefault.png'),"alt":"img5"},on:{"click":_vm.fakeClick}}),_c('img',{staticClass:"w-[124.3px] h-[240.25px] object-cover cursor-pointer",attrs:{"src":_vm.getBackCroppedComImageSix
                ? _vm.getBackCroppedComImageSix
                : require('@/assets/img/portraitDefault.png'),"alt":"img6"},on:{"click":_vm.fakeClick}})])]),_c('div',{staticClass:"h-[77.5px] pt-[5px]"},[_c('UserInfo',{attrs:{"width":378,"text":'15px',"data":_vm.getComBackFormInput}})],1)])]),_c('WaterMark')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }