<template>
  <form
    id="front-com-form"
    @submit="onSubmit"
    class="relative flex flex-col items-start justify-start bg-white lg:w-[300px] xl:w-[400px] 2xl:w-[500px] lg:h-[400px] xl:h-[500px] 2xl:h-[725px] overflow-hidden rounded-md drop-shadow-xl p-5"
    :class="{ 'overflow-y-auto': !loading }"
  >
    <div class="w-[100%]">
      <!-- Info -->
      <div class="mb-3 w-[100%]">
        <h1 class="text-lg font-semibold text-black">Info</h1>
        <p class="text-xs font-semibold text-gray-500">
          Add your information below.
        </p>
      </div>
      <!-- Orientation -->
      <h1 class="text-lg font-semibold text-black">Orientation</h1>
      <select
        class="mb-3 w-[100%] text-xs font-semibold text-gray-500 border-b border-gray-500 py-2 focus:outline-0"
        v-model="orientation"
      >
        <option value="p">Portrait</option>
        <option value="l">Landscape</option>
      </select>
      <!-- Name -->
      <input
        type="text"
        class="w-[100%] text-xs text-gray-500 px-1 py-2 border-b border-gray-500 mb-3 focus:outline-0"
        placeholder="Your Name Here"
        v-model="frontFormInput.name"
        @click="nullifyName"
        required
      />
      <!-- Tagline -->
      <input
        type="text"
        class="w-[100%] text-xs text-gray-500 px-1 py-2 border-b border-gray-500 mb-5 focus:outline-0"
        placeholder="Your Tagline Here"
        v-model="frontFormInput.tagline"
        @click="nullifyTag"
      />
      <!-- Hidden File Type Input -->
      <input
        type="file"
        id="imageUpload"
        accept="image/png, image/jpeg"
        class="hidden"
        @change="onFileChange"
      />
      <!-- Insert Image Trigger -->
      <button
        type="button"
        @click="fakeClick"
        class="cursor-pointer py-2 font-semibold flex flex-row items-center justify-center space-x-3 w-[100%] bg-transparent border border-primary text-primary rounded-md"
      >
        <VueIcons name="image" class="w-6 h-6" />
        <span class="text-sm">Add Image</span>
      </button>
      <!-- Design -->
      <div class="my-3 w-[100%]">
        <h1 class="text-lg font-semibold text-black">Design</h1>
        <p class="text-xs font-semibold text-gray-500">
          Add and design your information below.
        </p>
      </div>
      <!-- Font and Font Size -->
      <div
        class="w-[100%] mb-3 flex flex-row space-x-3 items-center justify-between"
      >
        <select
          v-model="frontFormInput.font"
          class="flex-1 text-xs font-semibold text-gray-500 border-b border-gray-500 py-2 focus:outline-0"
        >
          <option value="Abril Fatface, cursive">Abril Fatface, cursive</option>
          <option value="Montserrat, sans-serif">Montserrat, sans-serif</option>
          <option value="Boogaloo, cursive">Boogaloo, cursive</option>
          <option value="Chelsea Market, cursive">
            Chelsea Market, cursive
          </option>
          <option value="Finger Paint, cursive">Finger Paint, cursive</option>
          <option value="Kanit, sans-serif">Kanit, sans-serif</option>
          <option value="Lora, sans-serif">Lora, sans-serif</option>
          <option value="Open Sans, sans-serif">Open Sans, sans-serif</option>
          <option value="Playfair Display, sans-serif">
            Playfair Display, sans-serif
          </option>
          <option value="Poiret One, sans-serif">Poiret One, sans-serif</option>
          <option value="Poppins, sans-serif">Poppins, sans-serif</option>
          <option value="Raleway, sans-serif">Raleway, sans-serif</option>
          <option value="Roboto, sans-serif">Roboto, sans-serif</option>
          <option value="Syncopate, sans-serif">Syncopate, sans-serif</option>
          <option value="Ubuntu, sans-serif">Ubuntu, sans-serif</option>
        </select>
        <div
          class="flex flex-row w-[25%] space-x-2 items-center justify-center border-b border-gray-500 py-[5px]"
        >
          <div @click="decrementNameSize" class="cursor-pointer">
            <VueIcons name="circle-minus" class="w-6 h-6" />
          </div>
          <input
            disabled
            type="text"
            placeholder="36"
            class="w-[25%] text-center"
            v-model="frontFormInput.nameSize"
          />
          <div @click="incrementNameSize" class="cursor-pointer">
            <VueIcons name="circle-plus" class="w-6 h-6" />
          </div>
        </div>
      </div>
      <!-- Text Alignment -->
      <select
        class="mb-3 w-[100%] text-xs font-semibold text-gray-500 border-b border-gray-500 py-2 focus:outline-0"
        v-model="frontFormInput.textAlign"
      >
        <option value="left">Left</option>
        <option value="right">Right</option>
        <option value="center">Center</option>
      </select>
      <p class="text-xs font-semibold text-gray-500">Properties for Name</p>
      <!-- BG Color -->
      <div
        class="my-1 w-[100%] flex flex-row items-center justify-between text-gray-500 text-xs border-b border-gray-500"
      >
        <span class="flex w-[100%] text-xs text-gray-400">BG Color</span>
        <div class="flex flex-row items-center justify-end space-x-3 w-[100%]">
          <span class="uppercase">{{ frontFormInput.bgColor }}</span>
          <Verte
            model="hex"
            menuPosition="top"
            :enableAlpha="false"
            :draggable="false"
            :showHistory="null"
            class="border border-gray-300 rounded-full mb-[5.5px] cursor-pointer"
            v-model="frontFormInput.bgColor"
          />
        </div>
      </div>
      <!-- Font Color -->
      <div
        class="my-1 w-[100%] flex flex-row items-center justify-between text-gray-500 text-xs border-b border-gray-500"
      >
        <span class="flex w-[100%] text-xs text-gray-400">Font Color</span>
        <div class="flex flex-row items-center justify-end space-x-3 w-[100%]">
          <span class="uppercase">{{ frontFormInput.nameColor }}</span>
          <Verte
            model="hex"
            menuPosition="top"
            :enableAlpha="false"
            :draggable="false"
            :showHistory="null"
            class="border border-gray-300 rounded-full mb-[5.5px] cursor-pointer"
            v-model="frontFormInput.nameColor"
          />
        </div>
      </div>
      <p class="text-xs font-semibold text-gray-500 my-3">
        Properties for Tagline
      </p>
      <!-- Font Color and Font Size -->
      <div
        class="w-[100%] mb-3 flex flex-row space-x-3 items-center justify-between"
      >
        <div
          class="flex flex-row items-center justify-center border-b border-gray-500 py-[5.9px]"
        >
          <div @click="decrementTagSize" class="cursor-pointer">
            <VueIcons name="circle-minus" class="w-6 h-6" />
          </div>
          <input
            disabled
            type="text"
            placeholder="18"
            class="w-[100%] text-center"
            v-model="frontFormInput.tagSize"
          />
          <div @click="incrementTagSize" class="cursor-pointer">
            <VueIcons name="circle-plus" class="w-6 h-6" />
          </div>
        </div>
        <div
          class="pb-1 w-[100%] flex flex-row items-center justify-between text-gray-500 text-xs border-b border-gray-500"
        >
          <span class="flex w-[100%] text-xs text-gray-400">Font Color</span>
          <div
            class="flex flex-row items-center justify-end space-x-3 w-[100%]"
          >
            <span class="uppercase">{{ frontFormInput.tagColor }}</span>
            <Verte
              model="hex"
              menuPosition="top"
              :enableAlpha="false"
              :draggable="false"
              :showHistory="null"
              class="border border-gray-300 rounded-full mb-[5.5px] cursor-pointer"
              v-model="frontFormInput.tagColor"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row w-[100%] justify-end mt-3">
      <button
        v-if="!loading"
        type="submit"
        class="px-5 py-2 rounded-md bg-primary text-xs text-white font-semibold cursor-pointer"
      >
        Next
      </button>
      <SpinLoader v-else :text="'Please Wait'" />
    </div>
  </form>
</template>

<script>
import Verte from "verte";
import html2canvas from "html2canvas";
import { mapMutations, mapGetters } from "vuex";

import "verte/dist/verte.css";
import VueIcons from "@/utils/VueIcons.vue";
import SpinLoader from "@/utils/SpinLoader.vue";

export default {
  name: "FrontForm",
  components: {
    Verte,
    VueIcons,
    SpinLoader,
  },
  data() {
    return {
      loading: false,
      orientation: "p",
      frontFormInput: {
        name: "Your Name Here",
        tagline: "Your Tagline Here",
        bgColor: "#FFFFFF",
        font: "Abril Fatface, cursive",
        textAlign: "left",
        nameSize: 36,
        nameColor: "#000000",
        tagSize: 18,
        tagColor: "#000000",
      },
    };
  },
  computed: {
    ...mapGetters(["getFrontCroppedComImage"]),
  },
  methods: {
    ...mapMutations([
      "setComBorder",
      "setComFrontPdf",
      "setFrontComImage",
      "setComOrientation",
      "setComFrontFormInput",
    ]),
    fakeClick() {
      document.getElementById("imageUpload").click();
    },
    onFileChange(e) {
      if (
        e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/png"
      ) {
        this.setFrontComImage({
          frontComImage: URL.createObjectURL(e.target.files[0]),
        });
      } else {
        this.$toasted.show(
          "Image Format Wrong, Please Upload JPEG, JPG or PNG Only!"
        );
      }
      e.target.value = null;
    },
    nullifyName(e) {
      let user = e.target.value;
      if (e.target.value === "") {
        this.frontFormInput.name = "";
      } else if (e.target.value === "Your Name Here") {
        this.frontFormInput.name = "";
      } else {
        this.frontFormInput.name = user;
      }
    },
    nullifyTag(e) {
      let tag = e.target.value;
      if (e.target.value === "") {
        this.frontFormInput.tagline = "";
      } else if (e.target.value === "Your Tagline Here") {
        this.frontFormInput.tagline = "";
      } else {
        this.frontFormInput.tagline = tag;
      }
    },
    incrementNameSize() {
      if (this.frontFormInput.nameSize !== 36) {
        this.frontFormInput.nameSize++;
      }
    },
    decrementNameSize() {
      this.frontFormInput.nameSize--;
    },
    incrementTagSize() {
      if (this.frontFormInput.tagSize !== 20) {
        this.frontFormInput.tagSize++;
      }
    },
    decrementTagSize() {
      this.frontFormInput.tagSize--;
    },
    async onSubmit(e) {
      e.preventDefault();
      await this.setComBorder({
        comBorder: false,
      });
      document.getElementById("front-com-form").scrollTop = 0;
      if (this.getFrontCroppedComImage === "") {
        this.$toasted.show("Please Upload an Image Before Proceeding");
      } else {
        const image1 = document.getElementById("main");
        if (this.orientation === "p") {
          this.loading = true;
          await html2canvas(image1, {
            orientation: "portrait",
            unit: "in",
            format: [5.625, 8.625],
            scale: 4,
          }).then((canvas) => {
            this.setComFrontPdf({
              comFrontPdf: canvas.toDataURL("image/jpeg"),
            });
          });
          this.loading = false;
        } else {
          this.loading = true;
          await html2canvas(image1, {
            orientation: "landscape",
            unit: "in",
            format: [8.625, 5.625],
            scale: 4,
          }).then((canvas) => {
            this.setComFrontPdf({
              comFrontPdf: canvas.toDataURL("image/jpeg"),
            });
          });
          this.loading = false;
        }
        this.$router.push("/comp-cards/bc");
      }
    },
  },
  watch: {
    orientation(newOrientation, oldOrientation) {
      if (newOrientation !== oldOrientation) {
        this.setComOrientation({ comOrientation: newOrientation });
      }
    },
    frontFormInput: {
      handler: function () {
        this.setComFrontFormInput({
          comFrontFormInput: this.frontFormInput,
        });
        localStorage.setItem("frontCom", JSON.stringify(this.frontFormInput));
      },
      deep: true,
    },
  },
  created() {
    if (localStorage.getItem("frontCom")) {
      Object.assign(
        this.frontFormInput,
        JSON.parse(localStorage.getItem("frontCom"))
      );
    }
  },
};
</script>
