<template>
  <!-- Modal Background -->
  <div
    v-if="show"
    class="fixed inset-0 z-50 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center"
  >
    <!-- Modal Container -->
    <div class="w-[500px] flex flex-col">
      <!-- Close Icon -->
      <div
        @click="show = false"
        class="my-3 text-white place-self-end cursor-pointer"
      >
        <VueIcons name="circle-x" class="w-6 h-6" />
      </div>
      <!-- Modal Body -->
      <div
        class="bg-white rounded-t-md p-2 flex flex-row space-x-3 items-center justify-between"
      >
        <!-- <clipper-upload
          v-model="
            $data[
              this.$router.currentRoute.fullPath.includes('/f')
                ? frontImage
                : backImage
            ]
          "
        ></clipper-upload> -->
        <clipper-basic
          ref="clipper"
          class="basic md clipper-basic"
          bg-color="black"
          preview="preview"
          :ratio="ratio"
          :src="src"
        ></clipper-basic>
      </div>
      <!-- Modal Footer -->
      <div class="bg-white rounded-b-md p-2 border-t border-gray-300">
        <button
          @click="getCroppedResult(back_id)"
          class="cursor-pointer bg-primary px-3 py-2 text-white font-semibold rounded-md"
        >
          Clip Image
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import VueIcons from "../../utils/VueIcons.vue";

export default {
  name: "CropModal",
  components: {
    VueIcons,
  },
  data() {
    return {
      src: "",
      ratio: 0,
      back_id: 0,
      show: false,
    };
  },
  computed: {
    ...mapGetters([
      "getBackHeadImage",
      "getFrontComImage",
      "getFrontHeadImage",
      "getComOrientation",
      "getHeadOrientation",
      "getBackComImageOne",
      "getBackComImageTwo",
      "getBackComImageSix",
      "getBackComImageSeven",
      "getBackComImageEight",
      "getBackComImageNine",
      "getBackComImageFour",
      "getBackComImageFive",
      "getBackComImageThree",
      "getActiveHeadTemplate",
      "getActiveBackPTemplate",
      "getActiveBackLTemplate",
      "getActiveFrontPTemplate",
      "getActiveFrontLTemplate",
    ]),
  },
  methods: {
    ...mapMutations([
      "setComOrientation",
      "setHeadOrientation",
      "setBackCroppedComImage",
      "setBackCroppedHeadImage",
      "setFrontCroppedComImage",
      "setFrontCroppedHeadImage",
    ]),
    getCroppedResult(id) {
      const canvas = this.$refs.clipper.clip();
      const croppedImage = canvas.toDataURL("image/jpeg", 1);
      if (this.$router.currentRoute.fullPath.includes("/comp-cards")) {
        if (this.$router.currentRoute.fullPath.includes("/f")) {
          this.setFrontCroppedComImage({
            frontCroppedComImage: croppedImage,
          });
        } else {
          this.setBackCroppedComImage({
            id,
            backCroppedComImage: croppedImage,
          });
        }
      } else {
        if (this.$router.currentRoute.fullPath.includes("/f")) {
          this.setFrontCroppedHeadImage({
            frontCroppedHeadImage: croppedImage,
          });
        } else {
          this.setBackCroppedHeadImage({
            backCroppedHeadImage: croppedImage,
          });
        }
      }
      this.show = false;
    },
  },
  watch: {
    getFrontHeadImage(newFrontImage, oldFrontImage) {
      this.src = newFrontImage;
      if (newFrontImage !== oldFrontImage) {
        if (this.getHeadOrientation === "p") {
          if (this.getActiveHeadTemplate === 0) {
            this.ratio = 0.936;
          } else if (this.getActiveHeadTemplate === 1) {
            this.ratio = 0.991;
          } else {
            this.ratio = 0.804;
          }
        } else {
          if (this.getActiveHeadTemplate === 0) {
            this.ratio = 1.597;
          } else if (this.getActiveHeadTemplate === 1) {
            this.ratio = 1.562;
          } else {
            this.ratio = 1.242;
          }
        }
        this.show = true;
      }
    },
    getFrontComImage(newFrontImage, oldFrontImage) {
      this.src = newFrontImage;
      if (newFrontImage !== oldFrontImage) {
        if (this.getComOrientation === "p") {
          if (
            this.getActiveFrontPTemplate === 0 ||
            this.getActiveFrontPTemplate === 3 ||
            this.getActiveFrontPTemplate === 5 ||
            this.getActiveFrontPTemplate === 7
          ) {
            this.ratio = 0.652;
          } else if (this.getActiveFrontPTemplate === 2) {
            this.ratio = 0.763;
          } else if (this.getActiveFrontPTemplate === 1) {
            this.ratio = 0.748;
          } else if (this.getActiveFrontPTemplate === 6) {
            this.ratio = 0.733;
          } else if (this.getActiveFrontPTemplate === 4) {
            this.ratio = 0.845;
          } else {
            this.ratio = 0.605;
          }
        } else {
          if (this.getActiveFrontLTemplate === 0) {
            this.ratio = 0.766;
          } else {
            this.ratio = 1.533;
          }
        }
        this.show = true;
      }
    },
    getBackHeadImage(newBackImage, oldBackImage) {
      this.src = newBackImage;
      if (newBackImage !== oldBackImage) {
        if (this.getHeadOrientation === "p") {
          this.ratio = 0.80487;
        } else {
          this.ratio = 1.24;
        }
        this.show = true;
      }
    },
    getBackComImageOne(newBackImage, oldBackImage) {
      this.back_id = 1;
      this.src = newBackImage;
      if (newBackImage !== oldBackImage) {
        if (this.getComOrientation === "p") {
          if (this.getActiveBackPTemplate === 0) {
            this.ratio = 0.705;
          } else if (this.getActiveBackPTemplate === 1) {
            this.ratio = 0.716;
          } else if (this.getActiveBackPTemplate === 2) {
            this.ratio = 0.776;
          } else if (this.getActiveBackPTemplate === 3) {
            this.ratio = 1.452;
          } else if (this.getActiveBackPTemplate === 4) {
            this.ratio = 0.603;
          } else if (this.getActiveBackPTemplate === 5) {
            this.ratio = 1.573;
          } else if (this.getActiveBackPTemplate === 6) {
            this.ratio = 0.776;
          } else if (this.getActiveBackPTemplate === 7) {
            this.ratio = 0.617;
          } else if (this.getActiveBackPTemplate === 8) {
            this.ratio = 0.62;
          } else if (this.getActiveBackPTemplate === 9) {
            this.ratio = 0.644;
          } else if (this.getActiveBackPTemplate === 10) {
            this.ratio = 1.323;
          } else if (this.getActiveBackPTemplate === 11) {
            this.ratio = 0.644;
          } else if (this.getActiveBackPTemplate === 12) {
            this.ratio = 0.653;
          } else if (this.getActiveBackPTemplate === 13) {
            this.ratio = 0.653;
          } else if (this.getActiveBackPTemplate === 14) {
            this.ratio = 0.682;
          } else if (this.getActiveBackPTemplate === 15) {
            this.ratio = 0.665;
          } else if (this.getActiveBackPTemplate === 16) {
            this.ratio = 0.665;
          } else if (this.getActiveBackPTemplate === 17) {
            this.ratio = 0.665;
          } else if (this.getActiveBackPTemplate === 18) {
            this.ratio = 0.665;
          } else {
            this.ratio = 0.719;
          }
        } else {
          if (this.getActiveBackLTemplate === 0) {
            this.ratio = 0.699;
          } else if (this.getActiveBackLTemplate === 1) {
            this.ratio = 0.653;
          } else {
            this.ratio = 1.938;
          }
        }
        this.show = true;
      }
    },
    getBackComImageTwo(newBackImage, oldBackImage) {
      this.back_id = 2;
      this.src = newBackImage;
      if (newBackImage !== oldBackImage) {
        if (this.getComOrientation === "p") {
          if (this.getActiveBackPTemplate === 0) {
            this.ratio = 1.409;
          } else if (this.getActiveBackPTemplate === 1) {
            this.ratio = 0.716;
          } else if (this.getActiveBackPTemplate === 2) {
            this.ratio = 1.585;
          } else if (this.getActiveBackPTemplate === 3) {
            this.ratio = 0.716;
          } else if (this.getActiveBackPTemplate === 4) {
            this.ratio = 0.603;
          } else if (this.getActiveBackPTemplate === 5) {
            this.ratio = 0.51;
          } else if (this.getActiveBackPTemplate === 6) {
            this.ratio = 0.776;
          } else if (this.getActiveBackPTemplate === 7) {
            this.ratio = 0.617;
          } else if (this.getActiveBackPTemplate === 8) {
            this.ratio = 0.62;
          } else if (this.getActiveBackPTemplate === 9) {
            this.ratio = 0.644;
          } else if (this.getActiveBackPTemplate === 10) {
            this.ratio = 0.644;
          } else if (this.getActiveBackPTemplate === 11) {
            this.ratio = 0.644;
          } else if (this.getActiveBackPTemplate === 12) {
            this.ratio = 0.653;
          } else if (this.getActiveBackPTemplate === 13) {
            this.ratio = 0.653;
          } else if (this.getActiveBackPTemplate === 14) {
            this.ratio = 0.682;
          } else if (this.getActiveBackPTemplate === 15) {
            this.ratio = 1.387;
          } else if (this.getActiveBackPTemplate === 16) {
            this.ratio = 0.665;
          } else if (this.getActiveBackPTemplate === 17) {
            this.ratio = 0.665;
          } else {
            this.ratio = 0.665;
          }
        } else {
          if (this.getActiveBackLTemplate === 0) {
            this.ratio = 1.421;
          } else if (this.getActiveBackLTemplate === 1) {
            this.ratio = 0.653;
          } else {
            this.ratio = 1.917; // Placeholder
          }
        }
        this.show = true;
      }
    },
    getBackComImageThree(newBackImage, oldBackImage) {
      this.back_id = 3;
      this.src = newBackImage;
      if (newBackImage !== oldBackImage) {
        if (this.getComOrientation === "p") {
          if (this.getActiveBackPTemplate === 0) {
            this.ratio = 1.409;
          } else if (this.getActiveBackPTemplate === 1) {
            this.ratio = 0.716;
          } else if (this.getActiveBackPTemplate === 2) {
            this.ratio = 1.585;
          } else if (this.getActiveBackPTemplate === 3) {
            this.ratio = 0.716;
          } else if (this.getActiveBackPTemplate === 4) {
            this.ratio = 0.686; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 5) {
            this.ratio = 0.51;
          } else if (this.getActiveBackPTemplate === 6) {
            this.ratio = 0.51;
          } else if (this.getActiveBackPTemplate === 7) {
            this.ratio = 0.617;
          } else if (this.getActiveBackPTemplate === 8) {
            this.ratio = 0.673; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 9) {
            this.ratio = 0.695; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 10) {
            this.ratio = 1.39; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 11) {
            this.ratio = 0.644;
          } else if (this.getActiveBackPTemplate === 12) {
            this.ratio = 1.342;
          } else if (this.getActiveBackPTemplate === 13) {
            this.ratio = 0.653;
          } else if (this.getActiveBackPTemplate === 14) {
            this.ratio = 0.801;
          } else if (this.getActiveBackPTemplate === 15) {
            this.ratio = 1.387;
          } else if (this.getActiveBackPTemplate === 16) {
            this.ratio = 0.665;
          } else if (this.getActiveBackPTemplate === 17) {
            this.ratio = 0.665;
          } else {
            this.ratio = 0.665;
          }
        } else {
          if (this.getActiveBackLTemplate === 0) {
            this.ratio = 1.421;
          } else if (this.getActiveBackLTemplate === 1) {
            this.ratio = 0.653;
          } else {
            this.ratio = 1.917; // Placeholder
          }
        }
        this.show = true;
      }
    },
    getBackComImageFour(newBackImage, oldBackImage) {
      this.back_id = 4;
      this.src = newBackImage;
      if (newBackImage !== oldBackImage) {
        if (this.getComOrientation === "p") {
          if (this.getActiveBackPTemplate === 0) {
            this.ratio = 0.705;
          } else if (this.getActiveBackPTemplate === 1) {
            this.ratio = 0.716;
          } else if (this.getActiveBackPTemplate === 2) {
            this.ratio = 0.517;
          } else if (this.getActiveBackPTemplate === 3) {
            this.ratio = 0.844; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 4) {
            this.ratio = 0.686; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 5) {
            this.ratio = 0.51;
          } else if (this.getActiveBackPTemplate === 6) {
            this.ratio = 0.51;
          } else if (this.getActiveBackPTemplate === 7) {
            this.ratio = 0.617;
          } else if (this.getActiveBackPTemplate === 8) {
            this.ratio = 0.673; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 9) {
            this.ratio = 0.695; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 10) {
            this.ratio = 1.39; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 11) {
            this.ratio = 0.695; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 12) {
            this.ratio = 0.706; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 13) {
            this.ratio = 0.653;
          } else if (this.getActiveBackPTemplate === 14) {
            this.ratio = 0.601;
          } else if (this.getActiveBackPTemplate === 15) {
            this.ratio = 0.665;
          } else if (this.getActiveBackPTemplate === 16) {
            this.ratio = 1.387;
          } else if (this.getActiveBackPTemplate === 17) {
            this.ratio = 0.665;
          } else {
            this.ratio = 0.665;
          }
        } else {
          if (this.getActiveBackLTemplate === 0) {
            this.ratio = 0.699;
          } else if (this.getActiveBackLTemplate === 1) {
            this.ratio = 0.639; // Placeholder
          } else {
            this.ratio = 1.917; // Placeholder
          }
        }
        this.show = true;
      }
    },
    getBackComImageFive(newBackImage, oldBackImage) {
      this.back_id = 5;
      this.src = newBackImage;
      if (newBackImage !== oldBackImage) {
        if (this.getComOrientation === "p") {
          if (this.getActiveBackPTemplate === 0) {
            this.ratio = 0.628; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 1) {
            this.ratio = 0.844; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 2) {
            this.ratio = 0.517;
          } else if (this.getActiveBackPTemplate === 3) {
            this.ratio = 0.844; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 4) {
            this.ratio = 0.686; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 5) {
            this.ratio = 0.59; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 6) {
            this.ratio = 0.51;
          } else if (this.getActiveBackPTemplate === 7) {
            this.ratio = 0.617;
          } else if (this.getActiveBackPTemplate === 8) {
            this.ratio = 0.673; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 9) {
            this.ratio = 0.695; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 10) {
            this.ratio = 1.39; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 11) {
            this.ratio = 0.695; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 12) {
            this.ratio = 0.706; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 13) {
            this.ratio = 0.706; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 14) {
            this.ratio = 0.514; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 15) {
            this.ratio = 0.665;
          } else if (this.getActiveBackPTemplate === 16) {
            this.ratio = 0.665;
          } else if (this.getActiveBackPTemplate === 17) {
            this.ratio = 0.665;
          } else {
            this.ratio = 0.665;
          }
        }
        this.show = true;
      }
    },
    getBackComImageSix(newBackImage, oldBackImage) {
      this.back_id = 6;
      this.src = newBackImage;
      if (newBackImage !== oldBackImage) {
        if (this.getComOrientation === "p") {
          if (this.getActiveBackPTemplate === 0) {
            this.ratio = 0.628; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 1) {
            this.ratio = 0.844; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 2) {
            this.ratio = 0.517;
          } else if (this.getActiveBackPTemplate === 3) {
            this.ratio = 0.844; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 4) {
            this.ratio = 0.686; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 5) {
            this.ratio = 0.59; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 6) {
            this.ratio = 0.59; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 7) {
            this.ratio = 0.617;
          } else if (this.getActiveBackPTemplate === 8) {
            this.ratio = 0.673; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 9) {
            this.ratio = 0.695; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 10) {
            this.ratio = 1.39; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 11) {
            this.ratio = 0.695; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 12) {
            this.ratio = 0.706; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 13) {
            this.ratio = 0.706; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 14) {
            this.ratio = 0.514; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 15) {
            this.ratio = 1.387;
          } else if (this.getActiveBackPTemplate === 16) {
            this.ratio = 0.665;
          } else if (this.getActiveBackPTemplate === 17) {
            this.ratio = 0.665;
          } else {
            this.ratio = 0.665;
          }
        }
        this.show = true;
      }
    },
    getBackComImageSeven(newBackImage, oldBackImage) {
      this.back_id = 7;
      this.src = newBackImage;
      if (newBackImage !== oldBackImage) {
        if (this.getComOrientation === "p") {
          if (this.getActiveBackPTemplate === 0) {
            this.ratio = 0.628; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 1) {
            this.ratio = 0.844; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 2) {
            this.ratio = 0.552;
          } else if (this.getActiveBackPTemplate === 3) {
            this.ratio = 0.844; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 4) {
            this.ratio = 0.686; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 5) {
            this.ratio = 0.59; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 6) {
            this.ratio = 0.59; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 7) {
            this.ratio = 0.59;
          } else if (this.getActiveBackPTemplate === 8) {
            this.ratio = 0.673; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 9) {
            this.ratio = 0.695; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 10) {
            this.ratio = 1.39; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 11) {
            this.ratio = 0.695; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 12) {
            this.ratio = 0.706; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 13) {
            this.ratio = 0.706; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 14) {
            this.ratio = 0.514; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 15) {
            this.ratio = 0.805; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 16) {
            this.ratio = 1.387;
          } else if (this.getActiveBackPTemplate === 17) {
            this.ratio = 0.665;
          } else {
            this.ratio = 0.665;
          }
        }
        this.show = true;
      }
    },
    getBackComImageEight(newBackImage, oldBackImage) {
      this.back_id = 8;
      this.src = newBackImage;
      if (newBackImage !== oldBackImage) {
        if (this.getComOrientation === "p") {
          if (this.getActiveBackPTemplate === 0) {
            this.ratio = 0.628; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 1) {
            this.ratio = 0.844; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 2) {
            this.ratio = 0.552;
          } else if (this.getActiveBackPTemplate === 3) {
            this.ratio = 0.844; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 4) {
            this.ratio = 0.686; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 5) {
            this.ratio = 0.59; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 6) {
            this.ratio = 0.59; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 7) {
            this.ratio = 0.59;
          } else if (this.getActiveBackPTemplate === 8) {
            this.ratio = 0.673; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 9) {
            this.ratio = 0.695; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 10) {
            this.ratio = 1.39; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 11) {
            this.ratio = 0.695; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 12) {
            this.ratio = 0.706; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 13) {
            this.ratio = 0.706; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 14) {
            this.ratio = 0.514; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 15) {
            this.ratio = 0.805; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 16) {
            this.ratio = 0.805; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 17) {
            this.ratio = 1.387;
          } else {
            this.ratio = 0.665;
          }
        }
        this.show = true;
      }
    },
    getBackComImageNine(newBackImage, oldBackImage) {
      this.back_id = 9;
      this.src = newBackImage;
      if (newBackImage !== oldBackImage) {
        if (this.getComOrientation === "p") {
          if (this.getActiveBackPTemplate === 0) {
            this.ratio = 0.628; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 1) {
            this.ratio = 0.844; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 2) {
            this.ratio = 0.552;
          } else if (this.getActiveBackPTemplate === 3) {
            this.ratio = 0.844; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 4) {
            this.ratio = 0.686; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 5) {
            this.ratio = 0.59; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 6) {
            this.ratio = 0.59; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 7) {
            this.ratio = 0.59;
          } else if (this.getActiveBackPTemplate === 8) {
            this.ratio = 0.673; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 9) {
            this.ratio = 0.695; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 10) {
            this.ratio = 1.39; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 11) {
            this.ratio = 0.695; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 12) {
            this.ratio = 0.706; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 13) {
            this.ratio = 0.706; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 14) {
            this.ratio = 0.514; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 15) {
            this.ratio = 0.805; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 16) {
            this.ratio = 0.805; // Placeholder Only
          } else if (this.getActiveBackPTemplate === 17) {
            this.ratio = 0.805; // Placeholder Only
          } else {
            this.ratio = 0.665;
          }
        }
        this.show = true;
      }
    },
  },
};
</script>
