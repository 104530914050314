import { render, staticRenderFns } from "./ComBackBar.vue?vue&type=template&id=2b986685&"
import script from "./ComBackBar.vue?vue&type=script&lang=js&"
export * from "./ComBackBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports