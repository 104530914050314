<template>
  <form
    id="checkout-form"
    @submit="toPayment"
    class="relative flex flex-col items-start justify-start bg-white lg:w-[300px] xl:w-[400px] 2xl:w-[500px] lg:h-[400px] xl:h-[500px] 2xl:h-[725px] overflow-hidden rounded-md drop-shadow-xl p-5"
    :class="{ 'overflow-y-auto': !loading }"
  >
    <h1 class="text-lg font-semibold text-black mb-3 w-[100%]">
      Product Selection
    </h1>
    <!-- Toggles -->
    <div class="w-[100%] flex flex-row space-x-2 items-center justify-between">
      <p class="text-xs font-semibold text-gray-500">Printed Copies</p>
      <div @click="printedCopies = !printedCopies" class="cursor-pointer">
        <RadioToggle :trigger="printedCopies" />
      </div>
      <p class="text-xs font-semibold text-gray-500">Digital Download ($10)</p>
      <div @click="digitalDownload = !digitalDownload" class="cursor-pointer">
        <RadioToggle :trigger="digitalDownload" />
      </div>
    </div>
    <!-- Print Options -->
    <div
      v-if="printedCopies"
      class="w-[100%] grid grid-cols-3 gap-x-3 place-items-center justify-content-between"
    >
      <!-- Printed Quantity -->
      <div class="w-[100%] flex flex-col items-center justify-center">
        <p
          class="col-span-4 w-[100%] text-xs font-semibold text-black my-3 text-left"
        >
          Printed Quantity
        </p>
        <select
          :required="printedCopies"
          v-if="this.$router.currentRoute.fullPath.includes('/headshots')"
          v-model="printed.copies"
          class="w-[100%] border border-gray-300 rounded-lg text-xs"
        >
          <option :value="65">25 ($65)</option>
          <option :value="85">50 ($85)</option>
          <option :value="99">75 ($99)</option>
          <option :value="115">100 ($115)</option>
        </select>
        <select
          v-else
          v-model="printed.copies"
          class="w-[100%] border border-gray-300 rounded-lg text-xs"
        >
          <option :value="68">50 ($68)</option>
          <option :value="88">75 ($88)</option>
          <option :value="110">100 ($110)</option>
          <option :value="168">250 ($168)</option>
        </select>
      </div>
      <!-- Coating -->
      <div class="w-[100%] flex flex-col items-center justify-center">
        <p
          class="col-span-4 w-[100%] text-xs font-semibold text-black my-3 text-left"
        >
          Coating
        </p>
        <select
          :required="printedCopies"
          v-model="printed.coating"
          class="w-[100%] border border-gray-300 rounded-lg text-xs"
        >
          <option value="">Select Option</option>
          <option :value="'High Gloss'">High Gloss</option>
          <option
            v-if="this.$router.currentRoute.fullPath.includes('/comp-cards')"
            :value="'Matte Finish'"
          >
            Matte Finish
          </option>
        </select>
      </div>
      <!-- Shipping Method -->
      <div class="w-[100%] flex flex-col items-center justify-center">
        <p
          class="col-span-4 w-[100%] text-xs font-semibold text-black my-3 text-left"
        >
          Shipping Method
        </p>
        <select
          :required="printedCopies"
          v-model="printed.shippingCost"
          class="w-[100%] border border-gray-300 rounded-lg text-xs"
        >
          <option :value="0">Free ($0)</option>
          <option :value="59">Overnight Next Bus ($59)</option>
          <option :value="42">2 Bus Day ($42)</option>
          <option :value="32">3 Bus Day ($32)</option>
        </select>
      </div>
    </div>
    <!-- User Details -->
    <h1 class="text-lg font-semibold text-black my-3 w-[100%]">User Details</h1>
    <!-- Full Name & Email -->
    <div
      class="w-[100%] flex flex-row items-center justify-center space-x-2 mb-3 text-xs font-semibold"
    >
      <div class="flex flex-col space-y-2 items-start justify-start w-[100%]">
        <label for="name">Name</label>
        <input
          name="name"
          type="text"
          placeholder="Full Name"
          class="border border-gray-300 rounded-md focus:outline-primary px-3 py-2 w-[100%]"
          v-model="fullName"
          required
        />
      </div>
      <div class="flex flex-col space-y-2 items-start justify-start w-[100%]">
        <label for="email">Email</label>
        <input
          name="email"
          type="email"
          placeholder="name@example.com"
          class="border border-gray-300 rounded-md focus:outline-primary px-3 py-2 w-[100%]"
          v-model="email"
          required
        />
      </div>
    </div>
    <!-- Phone -->
    <div
      class="flex flex-col space-y-2 items-start justify-start w-[50%] text-xs font-semibold"
    >
      <label for="phone">Phone</label>
      <input
        name="phone"
        type="text"
        placeholder="Phone"
        class="border border-gray-300 rounded-md focus:outline-primary px-3 py-2 w-[100%]"
        v-model="phone"
        required
      />
    </div>
    <!-- Billing Address -->
    <h1 class="text-lg font-semibold text-black my-3 w-[100%]">
      Billing Address
    </h1>
    <div class="flex flex-col w-[100%] mb-3 text-xs font-semibold space-y-2">
      <label for="bill-address">Address</label>
      <input
        name="bill-address"
        type="text"
        placeholder="Billing Address"
        class="border border-gray-300 rounded-md focus:outline-primary px-3 py-2"
        v-model="billing.address"
        required
      />
    </div>
    <!-- City & State -->
    <div
      class="w-[100%] flex flex-row items-center justify-between space-x-2 mb-3 text-xs font-semibold"
    >
      <div class="flex flex-col space-y-2 items-start justify-start w-[100%]">
        <label for="city">City</label>
        <input
          name="city"
          type="text"
          placeholder="City"
          class="border border-gray-300 rounded-md focus:outline-primary px-3 py-2 w-[100%]"
          v-model="billing.city"
          required
        />
      </div>
      <div class="flex flex-col space-y-2 items-start justify-start w-[100%]">
        <label for="state">State</label>
        <input
          name="state"
          type="text"
          placeholder="State"
          class="border border-gray-300 rounded-md focus:outline-primary px-3 py-2 w-[100%]"
          v-model="billing.state"
          required
        />
      </div>
    </div>
    <!-- Zip Code -->
    <div
      class="flex flex-col space-y-2 items-start justify-start w-[50%] text-xs font-semibold"
    >
      <label for="zipcode">Zip Code</label>
      <input
        name="zipcode"
        type="text"
        placeholder="Zip Code"
        class="border border-gray-300 rounded-md focus:outline-primary px-3 py-2 w-[100%]"
        v-model="billing.zipCode"
        required
      />
    </div>
    <!-- Shipping Address Toggle -->
    <div class="w-[100%] flex flex-row items-center justify-between mt-3">
      <p class="text-xs font-semibold text-gray-500">
        I have a different Shipping Address
      </p>
      <div @click="shippingAddress = !shippingAddress" class="cursor-pointer">
        <RadioToggle :trigger="shippingAddress" />
      </div>
    </div>
    <!-- Shipping Address -->
    <template v-if="shippingAddress">
      <h1 class="text-lg font-semibold text-black my-3 w-[100%]">
        Shipping Address
      </h1>
      <div class="flex flex-col w-[100%] mb-3 text-xs font-semibold space-y-2">
        <label for="ship-address">Address</label>
        <input
          name="ship-address"
          type="text"
          placeholder="Shipping Address"
          class="border border-gray-300 rounded-md focus:outline-primary px-3 py-2"
          v-model="shipping.address"
        />
      </div>
      <!-- City & State -->
      <div
        class="w-[100%] flex flex-row items-center justify-center space-x-2 mb-3 text-xs font-semibold"
      >
        <div class="w-[100%] flex flex-col space-y-2 items-start justify-start">
          <label for="city">City</label>
          <input
            name="city"
            type="text"
            placeholder="City"
            class="border border-gray-300 rounded-md focus:outline-primary px-3 py-2 w-[100%]"
            v-model="shipping.city"
          />
        </div>
        <div class="w-[100%] flex flex-col space-y-2 items-start justify-start">
          <label for="state">State</label>
          <input
            name="state"
            type="text"
            placeholder="State"
            class="border border-gray-300 rounded-md focus:outline-primary px-3 py-2 w-[100%]"
            v-model="shipping.state"
          />
        </div>
      </div>
      <!-- Zip Code -->
      <div
        class="flex flex-col space-y-2 items-start justify-start w-[50%] text-xs font-semibold"
      >
        <label for="zipcode">Zip Code</label>
        <input
          name="zipcode"
          type="text"
          placeholder="Zip Code"
          class="border border-gray-300 rounded-md focus:outline-primary px-3 py-2 w-[100%]"
          v-model="shipping.zipCode"
        />
      </div>
    </template>
    <!-- Order Summary -->
    <h1 class="text-lg font-semibold text-black my-3 w-[100%]">
      Order Summary
    </h1>
    <!-- Subtotal -->
    <div class="w-[100%] flex flex-row items-center justify-between mb-3">
      <p class="text-xs font-semibold text-black">Subtotal</p>
      <p class="text-xs font-semibold text-black">${{ this.subtotal }}</p>
    </div>
    <!-- Shipping -->
    <div class="w-[100%] flex flex-row items-center justify-between mb-3">
      <p class="text-xs font-semibold text-black">Shipping</p>
      <p class="text-xs font-semibold text-black">
        ${{ this.printed.shippingCost }}
      </p>
    </div>
    <!-- Discount Code Toggle -->
    <div class="w-[100%] flex flex-row items-center justify-between my-3">
      <p class="text-xs font-semibold text-gray-500">I have a discount code</p>
      <div @click="discount = !discount" class="cursor-pointer">
        <RadioToggle :trigger="discount" />
      </div>
    </div>
    <!-- Discount Code -->
    <div
      v-if="discount"
      class="w-[100%] flex flex-row space-x-2 items-center justify-between my-3"
    >
      <input
        type="text"
        placeholder="Discount Code Here"
        class="flex-1 border border-gray-300 focus:outline-primary rounded-md px-3 py-2 text-xs font-semibold"
        v-model="discountCode"
      />
      <button
        type="button"
        class="text-white font-semilbold text-xs bg-primary rounded-md px-3 py-2 cursor-pointer"
      >
        Apply
      </button>
    </div>
    <!-- Grand Total -->
    <div
      class="w-[100%] flex flex-row items-center justify-between mb-3 border-y border-gray-300 my-3 py-3"
    >
      <p class="text-xs font-semibold text-black">Total</p>
      <p class="text-xl font-semibold text-black">
        ${{ this.subtotal + this.printed.shippingCost }}
      </p>
    </div>
    <!-- Disclaimer -->
    <p class="text-xs font-semibold text-gray-500 text-justify w-[100%]">
      Your personal data will be used to process your order, support your
      experience throughout this website, and for other purposes as described in
      our privacy policy.
    </p>
    <!-- Payment Button -->
    <div
      class="mt-3 w-[100%] flex flex-row items-center justify-between"
      :class="{ 'absolute top-0 left-0 w-[100%] h-[100%]': loading }"
    >
      <button
        type="button"
        @click="toBackForm"
        class="flex flex-row items-center justify-center space-x-2 text-xs font-bold text-primary cursor-pointer"
      >
        <VueIcons name="chevron-left" class="w-6 h-6" />
        <span>Previous</span>
      </button>
      <button
        v-if="!loading"
        type="submit"
        class="bg-primary text-white text-xs font-semibold rounded-md px-5 py-2 cursor-pointer"
        :class="{ 'cursor-not-allowed bg-opacity-25': subtotal === 0 }"
        :disabled="subtotal === 0"
      >
        Pay USD{{ this.subtotal + this.printed.shippingCost }}
      </button>
      <SpinLoader v-else :text="'Processing Payment'" />
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import VueIcons from "@/utils/VueIcons.vue";
import SpinLoader from "@/utils/SpinLoader.vue";
import RadioToggle from "@/utils/RadioToggle.vue";

export default {
  name: "CheckoutForm",
  components: {
    VueIcons,
    SpinLoader,
    RadioToggle,
  },
  data() {
    return {
      loading: false,
      discount: false,
      printedCopies: true,
      digitalDownload: false,
      shippingAddress: false,
      // Checkout Data
      fullName: "",
      email: "",
      phone: "",
      printed: {
        copies: this.$router.currentRoute.fullPath.includes("/headshots")
          ? 65
          : 68,
        coating: "",
        shippingCost: 0,
      },
      shipping: {
        address: "",
        city: "",
        state: "",
        zipCode: "",
      },
      billing: {
        address: "",
        city: "",
        state: "",
        zipCode: "",
      },
      discountCode: null,
      subtotal: this.$router.currentRoute.fullPath.includes("/headshots")
        ? 65
        : 68,
    };
  },
  computed: {
    ...mapGetters([
      // "getFinalComPdf",
      // "getFinalHeadPdf",
      "getBackPdf",
      "getFrontPdf",
      "getComBackPdf",
      "getComFrontPdf",
      "getComFrontFormInput",
      "getHeadFrontFormInput",
      "getBackCroppedHeadImage",
    ]),
  },
  methods: {
    ...mapActions(["redirectPayment"]),
    copies() {
      if (this.$router.currentRoute.fullPath.includes("/headshots")) {
        if (this.printed.copies === 65) {
          return "25 ($65)";
        } else if (this.printed.copies === 85) {
          return "50 ($85)";
        } else if (this.printed.copies === 99) {
          return "75 ($99)";
        } else if (this.printed.copies === 115) {
          return "100 ($115)";
        } else {
          return "0 Copies";
        }
      } else {
        if (this.printed.copies === 68) {
          return "50 ($68)";
        } else if (this.printed.copies === 88) {
          return "75 ($88)";
        } else if (this.printed.copies === 110) {
          return "100 ($110)";
        } else if (this.printed.copies === 168) {
          return "250 ($168)";
        } else {
          return "0 Copies";
        }
      }
    },
    shippingCost() {
      if (this.printed.shippingCost === 0) {
        return "Free ($0)";
      } else if (this.printed.shippingCost === 59) {
        return "Overnight Next Bus ($59)";
      } else if (this.printed.shippingCost === 42) {
        return "2 Bus Day ($42)";
      } else {
        return "3 Bus Day ($32)";
      }
    },
    toBackForm(e) {
      e.preventDefault();
      this.$router.currentRoute.fullPath.includes("/comp-cards")
        ? this.$router.push("/comp-cards/bc")
        : this.$router.push("/headshots/bh");
    },
    toPayment(e) {
      e.preventDefault();
      document.getElementById("checkout-form").scrollTop = 0;
      this.loading = true;
      if (this.$router.currentRoute.fullPath.includes("/comp-cards")) {
        let checkout = {
          name: this.fullName,
          email: this.email,
          file: {
            front: this.getComFrontPdf,
            back: this.getComBackPdf,
          },
          order_data: {
            type: "comp-card",
            printedCopies: {
              copies: this.copies(),
              coating: this.printed.coating,
              shippingCost: this.shippingCost(),
            },
            digitalDownload: this.digitalDownload,
            shippingAddress:
              this.shipping.address === "" ? this.billing : this.shipping,
            billingAddress: this.billing,
            discountCode: this.discountCode,
            frontFormInput: this.getComFrontFormInput,
          },
          phone: this.phone,
          price: `${this.subtotal + this.printed.shippingCost}00`,
        };
        this.redirectPayment(checkout);
      } else {
        let checkout = {
          name: this.fullName,
          email: this.email,
          file: {
            front: this.getFrontPdf,
            back: this.getBackCroppedHeadImage === "" ? "" : this.getBackPdf,
          },
          order_data: {
            type: "headshot",
            printedCopies: {
              copies: this.copies(),
              coating: this.printed.coating,
              shippingCost: this.shippingCost(),
            },
            digitalDownload: this.digitalDownload,
            shippingAddress:
              this.shipping.address === "" ? this.billing : this.shipping,
            billingAddress: this.billing,
            discountCode: this.discountCode,
            frontFormInput: this.getHeadFrontFormInput,
          },
          phone: this.phone,
          price: `${this.subtotal + this.printed.shippingCost}00`,
        };
        this.redirectPayment(checkout);
      }
    },
  },
  created() {
    this.loading = false;
  },
  watch: {
    printedCopies(newPrintedCopies) {
      if (!newPrintedCopies) {
        this.printed.copies = 0;
        this.printed.coating = "";
        this.printed.shippingCost = 0;
      } else {
        this.printed.copies = this.$router.currentRoute.fullPath.includes(
          "/headshots"
        )
          ? 65
          : 68;
      }
    },
    printed: {
      handler: function (newVal) {
        if (this.digitalDownload) {
          this.subtotal = 10 + newVal.copies;
        } else {
          this.subtotal = newVal.copies;
        }
      },
      deep: true,
    },
    digitalDownload(newDigitalDownload) {
      newDigitalDownload ? (this.subtotal += 10) : (this.subtotal -= 10);
    },
  },
};
</script>

<style scoped>
* {
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}
</style>
