var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-row items-center justify-center bg-gray-200"},[_c('LeftBar'),_c('div',{staticClass:"flex mr-20"},[_c('router-view')],1),_c('CropModal'),(this.$router.currentRoute.fullPath.includes('/comp-cards'))?[(this.$router.currentRoute.fullPath.includes('/f'))?_c('div',{staticClass:"flex flex-1",class:_vm.getComOrientation === 'p'
          ? 'lg:mr-32 2xl:mr-64'
          : 'md:mr-[20px] lg:mr-[20px] 2xl:mr-64'},[(_vm.getComOrientation === 'p')?[(_vm.getActiveFrontPTemplate === 0)?_c('BackTemplateOne'):_vm._e(),(_vm.getActiveFrontPTemplate === 1)?_c('BackTemplateTwo'):_vm._e(),(_vm.getActiveFrontPTemplate === 2)?_c('BackTemplateThree'):_vm._e(),(_vm.getActiveFrontPTemplate === 3)?_c('BackTemplateFour'):_vm._e(),(_vm.getActiveFrontPTemplate === 4)?_c('BackTemplateFive'):_vm._e(),(_vm.getActiveFrontPTemplate === 5)?_c('BackTemplateSix'):_vm._e(),(_vm.getActiveFrontPTemplate === 6)?_c('BackTemplateSeven'):_vm._e(),(_vm.getActiveFrontPTemplate === 7)?_c('BackTemplateEight'):_vm._e(),(_vm.getActiveFrontPTemplate === 8)?_c('BackTemplateNine'):_vm._e()]:[(_vm.getActiveFrontLTemplate === 0)?_c('BackTemplateOneL'):_vm._e(),(_vm.getActiveFrontLTemplate === 1)?_c('BackTemplateTwoL'):_vm._e(),(_vm.getActiveFrontLTemplate === 2)?_c('BackTemplateThreeL'):_vm._e()]],2):(this.$router.currentRoute.fullPath.includes('/b'))?_c('div',{staticClass:"flex flex-1",class:_vm.getComOrientation === 'p'
          ? 'lg:mr-32 2xl:mr-64'
          : 'md:mr-[20px] lg:mr-[20px] 2xl:mr-64'},[(_vm.getComOrientation === 'l')?[(_vm.getActiveBackLTemplate === 0)?_c('ComBackTemplateOneL'):_vm._e(),(_vm.getActiveBackLTemplate === 1)?_c('ComBackTemplateTwoL'):_vm._e(),(_vm.getActiveBackLTemplate === 2)?_c('ComBackTemplateThreeL'):_vm._e()]:[(_vm.getActiveBackPTemplate === 0)?_c('ComBackTemplateOne'):_vm._e(),(_vm.getActiveBackPTemplate === 1)?_c('ComBackTemplateTwo'):_vm._e(),(_vm.getActiveBackPTemplate === 2)?_c('ComBackTemplateThree'):_vm._e(),(_vm.getActiveBackPTemplate === 3)?_c('ComBackTemplateFour'):_vm._e(),(_vm.getActiveBackPTemplate === 4)?_c('ComBackTemplateFive'):_vm._e(),(_vm.getActiveBackPTemplate === 5)?_c('ComBackTemplateSix'):_vm._e(),(_vm.getActiveBackPTemplate === 6)?_c('ComBackTemplateSeven'):_vm._e(),(_vm.getActiveBackPTemplate === 7)?_c('ComBackTemplateEight'):_vm._e(),(_vm.getActiveBackPTemplate === 8)?_c('ComBackTemplateNine'):_vm._e(),(_vm.getActiveBackPTemplate === 9)?_c('ComBackTemplateTen'):_vm._e(),(_vm.getActiveBackPTemplate === 10)?_c('ComBackTemplateEleven'):_vm._e(),(_vm.getActiveBackPTemplate === 11)?_c('ComBackTemplateTwelve'):_vm._e(),(_vm.getActiveBackPTemplate === 12)?_c('ComBackTemplateThirteen'):_vm._e(),(_vm.getActiveBackPTemplate === 13)?_c('ComBackTemplateFourteen'):_vm._e(),(_vm.getActiveBackPTemplate === 14)?_c('ComBackTemplateFifteen'):_vm._e(),(_vm.getActiveBackPTemplate === 15)?_c('ComBackTemplateSixteen'):_vm._e(),(_vm.getActiveBackPTemplate === 16)?_c('ComBackTemplateSeventeen'):_vm._e(),(_vm.getActiveBackPTemplate === 17)?_c('ComBackTemplateEighteen'):_vm._e(),(_vm.getActiveBackPTemplate === 18)?_c('ComBackTemplateNineteen'):_vm._e(),(_vm.getActiveBackPTemplate === 19)?_c('ComBackTemplateTwenty'):_vm._e()]],2):_c('div',{staticClass:"flex flex-1"},[_c('CheckoutTemplate')],1)]:[(this.$router.currentRoute.fullPath.includes('/f'))?_c('div',{staticClass:"flex flex-1",class:_vm.getHeadOrientation === 'p'
          ? 'lg:mr-32 2xl:mr-64'
          : 'md:mr-[20px] lg:mr-[20px] 2xl:mr-64'},[(_vm.getActiveHeadTemplate === 0)?_c('TemplateOne'):_vm._e(),(_vm.getActiveHeadTemplate === 1)?_c('TemplateTwo'):_vm._e(),(_vm.getActiveHeadTemplate === 2)?_c('TemplateThree'):_vm._e(),(_vm.getActiveHeadTemplate === 3)?_c('TemplateFour'):_vm._e(),(_vm.getActiveHeadTemplate === 4)?_c('TemplateFive'):_vm._e()],1):(this.$router.currentRoute.fullPath.includes('/b'))?_c('div',{staticClass:"flex flex-1",class:_vm.getHeadOrientation === 'p'
          ? 'lg:mr-32 2xl:mr-64'
          : 'md:mr-[20px] lg:mr-[20px] 2xl:mr-64'},[_c('BackHeadTemplate')],1):_c('div',{staticClass:"flex flex-1"},[_c('CheckoutTemplate')],1)],(this.$router.currentRoute.fullPath.includes('/comp-cards'))?[(this.$router.currentRoute.fullPath.includes('/fc'))?_c('ComFrontBar'):_vm._e(),(this.$router.currentRoute.fullPath.includes('/bc'))?_c('ComBackBar'):_vm._e()]:[(this.$router.currentRoute.fullPath.includes('/f'))?_c('HeadBar'):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }