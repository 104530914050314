<template>
  <div class="relative">
    <NoticeVue />
    <div
      id="main"
      :style="landscape.main"
      class="flex flex-col"
      :class="{ 'border border-indigo-600': border }"
    >
      <img
        v-if="getFrontCroppedComImage"
        :src="getFrontCroppedComImage"
        alt="front-image"
        id="image"
        :style="landscape.img"
      />
      <img
        v-else
        alt="default"
        id="preview-image"
        src="@/assets/img/default.png"
        :style="landscape.img"
      />
      <div
        class="relative w-[100%] h-[100%] z-10"
        :class="{ 'border border-red-600': border }"
      >
        <TemplateText :data="getComFrontFormInput" :styles="landscape" />
      </div>
      <div
        class="absolute bottom-0 left-0 w-[50%] h-[100%]"
        :style="{
          backgroundColor: getComFrontFormInput.bgColor,
        }"
      />
    </div>
    <WaterMark />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import NoticeVue from "@/utils/Notice.vue";
import WaterMark from "@/utils/WaterMark.vue";
import TemplateText from "@/utils/TemplateText.vue";

export default {
  name: "BackTemplateThree",
  components: {
    NoticeVue,
    WaterMark,
    TemplateText,
  },
  data() {
    return {
      border: true,
      input: {},
      landscape: {
        main: {
          width: "647px",
          height: "422px",
          padding: "12.5px",
          position: "relative",
        },
        img: {
          position: "absolute",
          top: 0,
          right: 0,
          width: "50%",
          height: "100%",
          "object-fit": "cover",
        },
        text: {
          width: "50%",
          padding: "12.5px",
          position: "absolute",
          bottom: 0,
          left: 0,
        },
        h3: {
          width: "100%",
          "font-size": 36,
        },
        p: {
          width: "100%",
          "font-size": 18,
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "getComBorder",
      "getComOrientation",
      "getComFrontFormInput",
      "getFrontCroppedComImage",
    ]),
  },
  methods: {
    nameHeight() {
      let height = 0;
      if (this.input.tagline === "") {
        height =
          Number(this.input.nameSize) + Number(this.input.tagSize) + "px";
      } else {
        height = this.input.nameSize + "px";
      }
      return height;
    },
    tagHeight() {
      let height = 0;
      if (this.input.name === "") {
        height = "66.5px";
      } else {
        height = this.input.tagSize + "px";
      }
      return height;
    },
    finalHeight() {
      if (this.input.tagSize === "") {
        return this.input.nameSize + "px";
      } else {
        return (
          37 + Number(this.input.nameSize) + Number(this.input.tagSize) + "px"
        );
      }
    },
  },
  watch: {
    input(newInput) {
      if (
        JSON.stringify(newInput) !== JSON.stringify(this.getComFrontFormInput)
      ) {
        this.input = this.getComFrontFormInput;
      }
    },
    getComBorder(newInput) {
      this.border = newInput;
    },
  },
  created() {
    this.border = this.getComBorder;
    this.input = this.getComFrontFormInput;
  },
};
</script>
