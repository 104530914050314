<template>
  <div
    id="text"
    class="flex flex-col"
    :style="[
      styles.text,
      {
        fontFamily: data.font,
        textAlign: data.textAlign,
        height: `calc(${nameHeight}+${tagHeight})`,
        backgroundColor:
          activeHeadTemplate === 3 ? 'transparent' : data.bgColor,
      },
    ]"
  >
    <span
      id="name"
      :style="[
        styles.h3,
        {
          color: data.nameColor,
          fontSize: data.nameSize + 'px',
          lineHeight: nameHeight,
        },
      ]"
    >
      {{ data.name === "" ? "Your Name Here" : data.name }}
    </span>
    <span
      v-if="data.tagline !== ''"
      class="mt-[10px]"
      id="tag"
      :style="[
        styles.p,
        {
          color: data.tagColor,
          fontSize: data.tagSize + 'px',
          lineHeight: data.tagSize + 'px',
        },
      ]"
    >
      {{ data.tagline }}
    </span>
  </div>
</template>

<script>
export default {
  name: "TemplateText",
  props: {
    data: Object,
    styles: Object,
    activeHeadTemplate: Number,
    nameHeight: String,
    tagHeight: String,
  },
};
</script>
