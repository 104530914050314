var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('NoticeVue'),_c('div',{staticClass:"w-[422px] h-[647px] p-[12.5px]",class:{ 'border border-indigo-600': _vm.border },style:({ backgroundColor: _vm.getComBackFormInput.bgColor }),attrs:{"id":"comBack"}},[_c('div',{staticClass:"w-[398px] h-[623px] p-[10px]",class:{ 'border border-red-600': _vm.border }},[_c('input',{staticClass:"hidden",attrs:{"type":"file","id":"imageUpload","accept":"image/png, image/jpeg"},on:{"change":_vm.onFileChange}}),_c('div',{staticClass:"w-[378px] h-[269.5px] grid grid-cols-2 gap-[10px]"},[_c('img',{staticClass:"w-[184px] h-[269.5px] object-cover cursor-pointer",attrs:{"src":_vm.getBackCroppedComImageOne
              ? _vm.getBackCroppedComImageOne
              : require('@/assets/img/portraitDefault.png'),"alt":"img1"},on:{"click":_vm.fakeClick}}),_c('img',{staticClass:"w-[184px] h-[269.5px] object-cover cursor-pointer",attrs:{"src":_vm.getBackCroppedComImageTwo
              ? _vm.getBackCroppedComImageTwo
              : require('@/assets/img/portraitDefault.png'),"alt":"img2"},on:{"click":_vm.fakeClick}})]),_c('div',{staticClass:"mt-[10px] w-[378px] h-[269.5px] grid grid-cols-7 gap-[10px]"},[_c('img',{staticClass:"w-[216px] h-[269.5px] object-cover cursor-pointer col-span-4",attrs:{"src":_vm.getBackCroppedComImageThree
              ? _vm.getBackCroppedComImageThree
              : require('@/assets/img/landscapeDefault.png'),"alt":"img3"},on:{"click":_vm.fakeClick}}),_c('img',{staticClass:"w-[162px] h-[269.5px] object-cover cursor-pointer col-span-3",attrs:{"src":_vm.getBackCroppedComImageFour
              ? _vm.getBackCroppedComImageFour
              : require('@/assets/img/portraitDefault.png'),"alt":"img4"},on:{"click":_vm.fakeClick}})]),_c('div',{staticClass:"flex flex-col items-start justify-center",style:({
          color: _vm.getComBackFormInput.fontColor,
        })},[_c('div',{staticClass:"w-[378px] text-[12px] flex flex-row items-start justify-center space-x-7"},[_c('div',{staticClass:"flex flex-col items-start justify-between"},[(_vm.getComBackFormInput.eyes)?_c('span',{staticClass:"font-bold"},[_vm._v("Eyes: "+_vm._s(_vm.getComBackFormInput.eyes))]):_vm._e(),(_vm.getComBackFormInput.height)?_c('span',{staticClass:"font-bold"},[_vm._v("Height: "+_vm._s(_vm.getComBackFormInput.height))]):_vm._e()]),(_vm.getComBackFormInput.sex !== 'k')?_c('div',{staticClass:"flex flex-col items-start justify-between"},[(
                _vm.getComBackFormInput.sex !== 'k' && _vm.getComBackFormInput.chest
              )?_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.getComBackFormInput.sex === "m" ? "Chest" : "Bust")+": "+_vm._s(_vm.getComBackFormInput.chest))]):_vm._e(),(
                _vm.getComBackFormInput.sex !== 'k' && _vm.getComBackFormInput.waist
              )?_c('span',{staticClass:"font-bold"},[_vm._v("Waist: "+_vm._s(_vm.getComBackFormInput.waist))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"flex flex-col items-start justify-between"},[(_vm.getComBackFormInput.hair)?_c('span',{staticClass:"font-bold"},[_vm._v("Hair: "+_vm._s(_vm.getComBackFormInput.hair))]):_vm._e(),(_vm.getComBackFormInput.shoe)?_c('span',{staticClass:"font-bold"},[_vm._v("Shoe: "+_vm._s(_vm.getComBackFormInput.shoe)+"  ")]):_vm._e()]),_c('div',{staticClass:"flex flex-col items-start justify-between"},[(
                _vm.getComBackFormInput.sex !== 'k' && _vm.getComBackFormInput.hip
              )?_c('span',{staticClass:"font-bold"},[_vm._v("Hip: "+_vm._s(_vm.getComBackFormInput.hip))]):_vm._e(),(
                (_vm.getComBackFormInput.sex === 'k' ||
                  _vm.getComBackFormInput.sex === 'a') &&
                _vm.getComBackFormInput.age
              )?_c('span',{staticClass:"font-bold"},[_vm._v("Age: "+_vm._s(_vm.getComBackFormInput.age))]):_vm._e()])]),(_vm.getComBackFormInput.other)?_c('p',{staticClass:"text-center text-wrap text-[12px] w-[378px]",style:({
            color: _vm.getComBackFormInput.fontColor,
          })},[_vm._v(" "+_vm._s(_vm.getComBackFormInput.other)+" ")]):_vm._e()])])]),_c('WaterMark')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }