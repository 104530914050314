<template>
  <div class="relative">
    <NoticeVue />
    <div
      id="comBack"
      class="w-[647px] h-[422px] p-[12.5px]"
      :class="{ 'border border-indigo-600': border }"
      :style="{ backgroundColor: getComBackFormInput.bgColor }"
    >
      <!-- Hidden File Type Input -->
      <input
        type="file"
        id="imageUpload"
        accept="image/png, image/jpeg"
        class="hidden"
        @change="onFileChange"
      />
      <div
        class="px-[10px] pb-[10px] w-[623px] h-[398px]"
        :class="{ 'border border-red-600': border }"
      >
        <img
          :src="
            getBackCroppedComImageOne
              ? getBackCroppedComImageOne
              : require('@/assets/img/landscapeDefault.png')
          "
          alt="img1"
          class="w-[601px] h-[310px] object-cover cursor-pointer"
          @click="fakeClick"
        />
        <div class="text-center w-[601px]">
          <UserInfo :width="601" :data="getComBackFormInput" />
        </div>
      </div>
    </div>
    <WaterMark />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import NoticeVue from "@/utils/Notice.vue";
import WaterMark from "@/utils/WaterMark.vue";
import UserInfo from "@/utils/UserInfo.vue";

export default {
  name: "ComBackTemplateThreeL",
  data() {
    return {
      border: true,
      image: "",
    };
  },
  components: {
    NoticeVue,
    WaterMark,
    UserInfo,
  },
  computed: {
    ...mapGetters([
      "getComBackFormInput",
      "getBackCroppedComImageOne",
      "getComBorder",
    ]),
  },
  methods: {
    ...mapMutations(["setBackComImage"]),
    fakeClick(e) {
      this.image = e.target.alt;
      document.getElementById("imageUpload").click();
    },
    onFileChange(e) {
      if (this.image === "img1") {
        this.setBackComImage({
          id: 1,
          backComImage: URL.createObjectURL(e.target.files[0]),
        });
      }
      e.target.value = null;
    },
  },
  watch: {
    getComBorder(newInput) {
      this.border = newInput;
    },
  },
  created() {
    this.border = this.getComBorder;
  },
};
</script>
