<template>
  <form
    id="back-head-form"
    @submit="onSubmit"
    class="relative flex flex-col items-start justify-start bg-white lg:w-[300px] xl:w-[400px] 2xl:w-[500px] lg:h-[400px] xl:h-[500px] 2xl:h-[725px] overflow-hidden rounded-md drop-shadow-xl p-5"
    :class="{ 'overflow-y-auto': !loading }"
  >
    <div class="w-[100%]">
      <!-- Info -->
      <div class="mb-3 w-[100%]">
        <h1 class="text-lg font-semibold text-black">Info</h1>
        <p class="text-xs font-semibold text-gray-500">
          Add your information below.
        </p>
      </div>
      <!-- Back Print Toggle -->
      <div class="flex flex-row items-center justify-between w-[100%]">
        <p class="text-xs font-semibold text-gray-500">Print on Back ?</p>
        <div @click="backPrint = !backPrint" class="cursor-pointer">
          <RadioToggle :trigger="backPrint" />
        </div>
      </div>
      <template v-if="backPrint">
        <!-- Hidden File Type Input -->
        <input
          target="_blank"
          type="file"
          id="imageUpload"
          class="hidden"
          :accept="
            backHeadFileType === 'png' || backHeadFileType === 'jpeg'
              ? 'image/png, image/jpeg'
              : 'application/pdf'
          "
          @change="onFileChange"
        />
        <!-- File Type -->
        <select
          v-model="backHeadFileType"
          class="my-5 w-[100%] text-xs font-semibold text-gray-500 border-b border-gray-500 py-2 focus:outline-0"
        >
          <option value="png">PNG</option>
          <option value="jpeg">JPEG</option>
          <option value="pdf">PDF</option>
        </select>
        <button
          type="button"
          @click="fakeClick"
          class="cursor-pointer border-2 border-primary rounded-md w-[100%] flex flex-row space-x-2 items-center justify-center py-1 text-primary text-xs font-bold"
        >
          <VueIcons name="cloud-arrow-up" class="w-6 h-6" />
          <span>Select File</span>
        </button>
      </template>
    </div>
    <!-- Previous & Next -->
    <div class="my-5 w-[100%] flex flex-row items-center justify-between">
      <button
        type="button"
        @click="toFrontForm"
        class="flex flex-row items-center justify-center space-x-2 text-xs font-bold text-primary cursor-pointer"
      >
        <VueIcons name="chevron-left" class="w-6 h-6" />
        <span>Previous</span>
      </button>
      <button
        v-if="!loading"
        type="submit"
        class="bg-primary text-white text-xs font-semibold rounded-md px-5 py-2 cursor-pointer"
      >
        Next
      </button>
      <SpinLoader v-else :text="'Please Wait'" />
    </div>
  </form>
</template>

<!-- PDF.js -->
<script
  src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.min.js"
  integrity="sha512-RV+l/3iMDTmIt64ztJmriqLRZl2IwGHcG+655gFxUa20Uq9GljEsY3wibq5BZkGzRlMbYFHUPelVQvWvZxP38w=="
  crossorigin="anonymous"
  referrerpolicy="no-referrer"
></script>

<script>
import html2canvas from "html2canvas";
import { mapMutations, mapGetters } from "vuex";

import VueIcons from "@/utils/VueIcons.vue";
import SpinLoader from "@/utils/SpinLoader.vue";
import RadioToggle from "@/utils/RadioToggle.vue";

export default {
  name: "BackHeadForm",
  components: { VueIcons, RadioToggle, SpinLoader },
  data() {
    return {
      loading: false,
      backPrint: false,
      backHeadFileType: "jpeg",
    };
  },
  computed: {
    ...mapGetters(["getHeadOrientation", "getBackCroppedHeadImage"]),
  },
  methods: {
    ...mapMutations(["setBackHeadImage", "setBackPdf"]),
    fakeClick() {
      document.getElementById("imageUpload").click();
    },
    onFileChange(e) {
      this.loading = true;
      const file = URL.createObjectURL(e.target.files[0]);
      if (e.target.files[0].type === "application/pdf") {
        const pdf = pdfjsLib.getDocument(file);
        pdf.promise.then((response) => {
          response.getPage(1).then((page) => {
            var scale = 4;
            var viewport = page.getViewport({ scale: scale });
            var outputScale = window.devicePixelRatio || 1;
            var canvas = document.getElementById("the-canvas");
            var context = canvas.getContext("2d");

            canvas.width = Math.floor(viewport.width * outputScale);
            canvas.height = Math.floor(viewport.height * outputScale);
            canvas.style.width = Math.floor(viewport.width) + "px";
            canvas.style.height = Math.floor(viewport.height) + "px";

            var transform =
              outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

            var renderContext = {
              canvasContext: context,
              transform: transform,
              viewport: viewport,
            };
            page.render(renderContext);
          });
        });
        setTimeout(() => {
          this.setBackHeadImage({
            backHeadImage: document.getElementById("the-canvas").toDataURL(),
          });
          this.loading = false;
        }, 4000);
      } else {
        this.setBackHeadImage({
          backHeadImage: file,
        });
        this.loading = false;
      }
      e.target.value = null;
    },
    toFrontForm(e) {
      e.preventDefault();
      this.$router.currentRoute.fullPath.includes("/comp-cards")
        ? this.$router.push("/comp-cards/fc")
        : this.$router.push("/headshots/fh");
    },
    async onSubmit(e) {
      e.preventDefault();
      document.getElementById("back-head-form").scrollTop = 0;
      const backHeadPlaceholder = document.getElementById(
        "backHeadPlaceholder"
      );
      if (backHeadPlaceholder) {
        this.$router.currentRoute.fullPath.includes("/comp-cards")
          ? this.$router.push("/comp-cards/cc")
          : this.$router.push("/headshots/ch");
      } else {
        const image1 = document.getElementById("back");
        if (this.getOrientation === "p") {
          this.loading = true;
          await html2canvas(image1, {
            orientation: "portrait",
            unit: "in",
            format: [8.75, 10.75],
            scale: 4,
          }).then((canvas) => {
            this.setBackPdf({
              backPdf: canvas.toDataURL("image/jpeg"),
            });
          });
          this.$router.currentRoute.fullPath.includes("/comp-cards")
            ? this.$router.push("/comp-cards/cc")
            : this.$router.push("/headshots/ch");
          this.loading = false;
        } else {
          this.loading = true;
          await html2canvas(image1, {
            orientation: "landscape",
            unit: "in",
            format: [10.75, 8.75],
            scale: 4,
          }).then((canvas) => {
            this.setBackPdf({
              backPdf: canvas.toDataURL("image/jpeg"),
            });
          });
          this.$router.currentRoute.fullPath.includes("/comp-cards")
            ? this.$router.push("/comp-cards/cc")
            : this.$router.push("/headshots/ch");
          this.loading = false;
        }
      }
    },
  },
  created() {
    // Initializing PDF.js
    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.min.js"
    );
    plugin.async = true;
    document.head.appendChild(plugin);
  },
};
</script>
